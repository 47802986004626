import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  FaChevronDown, 
  FaEye, 
  FaPlus, 
  FaTrash, 
  FaSearch, 
  FaPrint,
  FaFilter,
  FaSort,
  FaWhatsapp,
  FaPaw,
  FaCat,
  FaDog,
  FaDove,
  FaHorse
} from 'react-icons/fa';
import Modal from 'react-modal';
import axios from 'axios';

function PatientsClinic() {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedOwner, setExpandedOwner] = useState(null);
  const [owners, setOwners] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newOwner, setNewOwner] = useState({
    name: '', phone_number: '', address: '', email: '', tc_no: '', registration_date: '', protocol_no: '', last_visit_date: ''
  });
  const [petModalIsOpen, setPetModalIsOpen] = useState(false);
  const [newPet, setNewPet] = useState({ name: '', breed: '', age: '', owner_name: '', species: '' });
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem('authToken');
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    axios.get('https://api.dev1.fugesoft.com/api/owners', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setOwners(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the owners!', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [token]);

  const toggleExpand = (id) => setExpandedOwner(expandedOwner === id ? null : id);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setNewOwner({
      name: '', phone_number: '', address: '', email: '', tc_no: '', registration_date: '', protocol_no: '', last_visit_date: ''
    });
  };
  
  const openPetModal = () => setPetModalIsOpen(true);
  const closePetModal = () => {
    setPetModalIsOpen(false);
    setNewPet({ name: '', breed: '', age: '', owner_name: '', species: '' });
  };

  const handleAddOwner = () => {
    axios.post('https://api.dev1.fugesoft.com/api/owners', newOwner, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setOwners([...owners, { ...newOwner, _id: response.data.owner_id, pets: [], total_price: 0 }]);
        closeModal();
      })
      .catch(error => {
        console.error('There was an error creating the owner!', error);
      });
  };

  const handleAddPet = () => {
    axios.post('https://api.dev1.fugesoft.com/api/pets', newPet, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        const updatedOwners = owners.map(owner => {
          if (owner.name === newPet.owner_name) {
            return { ...owner, pets: [...owner.pets, { ...newPet, _id: response.data.pet_id }] };
          }
          return owner;
        });
        setOwners(updatedOwners);
        closePetModal();
      })
      .catch(error => {
        console.error('There was an error adding the pet!', error);
      });
  };

  const handleDeleteOwner = (ownerId) => {
    if (window.confirm('Bu evcil hayvan sahibini silmek istediğinizden emin misiniz?')) {
      axios.delete(`https://api.dev1.fugesoft.com/api/owners/${ownerId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(() => setOwners(owners.filter(owner => owner._id !== ownerId)))
        .catch(error => console.error('There was an error deleting the owner!', error));
    }
  };

  const handleRowClick = (id) => {
    toggleExpand(id);
  };

  const handleOwnerClick = (e, ownerId) => {
    e.stopPropagation(); // Row'un genişlemesini engellemek için
    navigate(`/pet-owner/${ownerId}`);
  };

  const handleWhatsAppClick = (e, phoneNumber) => {
    e.stopPropagation(); // Row'un genişlemesini engellemek için
    // Telefon numarasını string'e çevir ve null/undefined kontrolü yap
    if (!phoneNumber) return;
    
    const phoneStr = String(phoneNumber);
    // Telefon numarasından tüm boşlukları ve özel karakterleri temizle
    const cleanNumber = phoneStr.replace(/[\s()-]/g, '');
    // Eğer numara + ile başlamıyorsa başına +90 ekle
    const formattedNumber = cleanNumber.startsWith('+') ? cleanNumber : `+90${cleanNumber}`;
    window.open(`https://wa.me/${formattedNumber}`, '_blank');
  };

  const filteredOwners = owners.filter((owner) =>
    owner.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    owner.phone_number.includes(searchTerm) ||
    owner.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
    owner.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getPetIcon = (species) => {
    const speciesLower = species.toLowerCase();
    if (speciesLower.includes('kedi')) {
      return { icon: FaCat, color: 'bg-orange-100 text-orange-500' };
    } else if (speciesLower.includes('köpek')) {
      return { icon: FaDog, color: 'bg-blue-100 text-blue-500' };
    } else if (speciesLower.includes('kuş')) {
      return { icon: FaDove, color: 'bg-sky-100 text-sky-500' };
    } else if (speciesLower.includes('at')) {
      return { icon: FaHorse, color: 'bg-amber-100 text-amber-500' };
    }
    return { icon: FaPaw, color: 'bg-purple-100 text-purple-500' };
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      {/* Header Section */}
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-800">Hasta Yönetimi</h2>
        <p className="text-gray-600 mt-1">Hasta ve sahip bilgilerini yönetin</p>
      </div>

      {/* Search and Actions Bar */}
      <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          {/* Search Bar */}
          <div className="relative flex-1 max-w-md">
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="İsim, telefon veya email ile ara..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Action Buttons */}
          <div className="flex gap-3">
            <button
              onClick={openModal}
              className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
            >
              <FaPlus size={16} />
              <span>Yeni Sahip</span>
            </button>
            <button
              onClick={openPetModal}
              className="flex items-center gap-2 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors"
            >
              <FaPlus size={16} />
              <span>Yeni Hasta</span>
            </button>
            <button
              className="flex items-center gap-2 bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 transition-colors"
            >
              <FaFilter size={16} />
              <span className="hidden md:inline">Filtrele</span>
            </button>
            <button
              className="flex items-center gap-2 bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 transition-colors"
            >
              <FaPrint size={16} />
              <span className="hidden md:inline">Yazdır</span>
            </button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="overflow-x-auto">
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <div className="relative">
                <div className="w-12 h-12 border-4 border-blue-200 rounded-full animate-spin"></div>
                <div className="w-12 h-12 border-4 border-blue-500 rounded-full animate-spin absolute top-0 left-0 border-t-transparent"></div>
              </div>
              <span className="ml-4 text-gray-600">Veriler yükleniyor...</span>
            </div>
          ) : owners.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-64">
              <div className="text-gray-500 text-lg mb-2">Henüz kayıtlı hasta sahibi bulunmuyor</div>
              <button
                onClick={openModal}
                className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
              >
                <FaPlus size={16} />
                <span>Yeni Sahip Ekle</span>
              </button>
            </div>
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="w-10 px-6 py-3 text-left"></th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Sahip Bilgileri
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    İletişim
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Kayıt Bilgileri
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Hasta Sayısı
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredOwners.map((owner) => (
                  <React.Fragment key={owner._id}>
                    <tr 
                      className="hover:bg-gray-50 transition-colors cursor-pointer"
                      onClick={() => handleRowClick(owner._id)}
                    >
                      <td className="px-6 py-4">
                        <button
                          className={`transition-transform duration-200 ${
                            expandedOwner === owner._id ? 'transform rotate-180' : ''
                          }`}
                        >
                          <FaChevronDown className="text-gray-500" />
                        </button>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-start">
                          <div>
                            <p 
                              className="text-sm font-medium text-blue-600 hover:text-blue-800 cursor-pointer"
                              onClick={(e) => handleOwnerClick(e, owner._id)}
                            >
                              {owner.name}
                            </p>
                            <p className="text-sm text-gray-500">{owner.tc_no}</p>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-900 flex items-center gap-2">
                          {owner.phone_number}
                          <button
                            onClick={(e) => handleWhatsAppClick(e, owner.phone_number)}
                            className="text-green-500 hover:text-green-600 transition-colors"
                            title="WhatsApp'ta mesaj gönder"
                          >
                            <FaWhatsapp size={16} />
                          </button>
                        </div>
                        <div className="text-sm text-gray-500">{owner.email}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-900">#{owner.protocol_no}</div>
                        <div className="text-sm text-gray-500">
                          {new Date(owner.registration_date).toLocaleDateString('tr-TR')}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                          {owner.pets ? owner.pets.length : 0} Hasta
                        </span>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-3">
                          <Link
                            to={`/pet-owner/${owner._id}`}
                            className="text-blue-600 hover:text-blue-800"
                            title="Detayları Görüntüle"
                          >
                            <FaEye size={18} />
                          </Link>
                          <button
                            onClick={() => handleDeleteOwner(owner._id)}
                            className="text-red-600 hover:text-red-800"
                            title="Sil"
                          >
                            <FaTrash size={16} />
                          </button>
                        </div>
                      </td>
                    </tr>

                    {/* Expanded Pet List */}
                    {expandedOwner === owner._id && owner.pets && owner.pets.length > 0 && (
                      <tr>
                        <td colSpan="6" className="px-6 py-4 bg-gray-50">
                          <div className="border rounded-lg overflow-hidden">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
                              {owner.pets.map((pet) => (
                                <Link
                                  to={`/pet-details/${pet._id}`}
                                  key={pet._id}
                                  className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow flex items-center gap-4"
                                >
                                  <div className={`flex-shrink-0 h-12 w-12 rounded-full flex items-center justify-center ${getPetIcon(pet.species).color}`}>
                                    {React.createElement(getPetIcon(pet.species).icon, { size: 24 })}
                                  </div>
                                  <div>
                                    <div className="font-medium text-gray-900">{pet.name}</div>
                                    <div className="text-sm text-gray-500">
                                      {pet.breed} • {pet.species}
                                    </div>
                                  </div>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {/* Keep existing modals but update their styling */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-xl"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4"
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-gray-800">Yeni Evcil Hayvan Sahibi Ekle</h2>
          <button
            onClick={closeModal}
            className="text-gray-400 hover:text-gray-600"
          >
            ✕
          </button>
        </div>

        <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Ad Soyad
            </label>
            <input
              type="text"
              value={newOwner.name}
              onChange={(e) => setNewOwner({ ...newOwner, name: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Add similar styling for other form fields */}

          <div className="col-span-2 flex justify-end gap-3 mt-4">
            <button
              type="button"
              onClick={closeModal}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              İptal
            </button>
            <button
              type="button"
              onClick={handleAddOwner}
              className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            >
              Kaydet
            </button>
          </div>
        </form>
      </Modal>

      {/* Similar updates for pet modal */}
    </div>
  );
}

export default PatientsClinic;
