import React, { useState, useEffect } from "react";
import {
  FaCalendarCheck,
  FaTasks,
  FaUsers,
  FaMedkit,
  FaArrowUp,
} from "react-icons/fa";
import axios from "axios";

function StatisticsWidgets() {
  const [counts, setCounts] = useState({
    owner_count: 0,
    pet_count: 0,
    appointment_count: 0,
  });

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    fetchCounts();
  }, []);

  const fetchCounts = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/counts", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCounts(response.data);
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
      {/* Toplam Sahip/Hayvan */}
      <div className="bg-white p-4 rounded-lg shadow-md hover:shadow-xl transition-all">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-base text-gray-700 font-semibold">Toplam Sahip/Hayvan</h3>
          <div className="p-2 bg-blue-50 rounded-lg">
            <FaUsers className="text-blue-500 text-lg" />
          </div>
        </div>
        <div className="flex items-baseline">
          <p className="text-2xl font-bold text-gray-800">{counts.owner_count}</p>
          <span className="text-base text-gray-600 ml-2">/ {counts.pet_count}</span>
        </div>
        <div className="text-xs text-green-600 font-medium mt-2">
          <FaArrowUp className="inline mr-1" />
          <span>+24 yeni kayıt</span>
        </div>
      </div>

      {/* SKT Yaklaşan Ürünler */}
      <div className="bg-white p-4 rounded-lg shadow-md hover:shadow-xl transition-all">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-base text-gray-700 font-semibold">SKT Yaklaşan Ürünler</h3>
          <div className="p-2 bg-red-50 rounded-lg">
            <FaMedkit className="text-red-500 text-lg" />
          </div>
        </div>
        <p className="text-2xl font-bold text-gray-800">17</p>
        <div className="flex gap-3 mt-2">
          <span className="text-xs bg-amber-50 text-amber-600 px-2 py-1 rounded-md font-medium">6 ürün - 3 gün içinde</span>
          <span className="text-xs bg-red-50 text-red-600 px-2 py-1 rounded-md font-medium">4 ürün - Bugün</span>
        </div>
      </div>

      {/* Bekleyen Görevler */}
      <div className="bg-white p-4 rounded-lg shadow-md hover:shadow-xl transition-all">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-base text-gray-700 font-semibold">Bekleyen Görevler</h3>
          <div className="p-2 bg-purple-50 rounded-lg">
            <FaTasks className="text-purple-500 text-lg" />
          </div>
        </div>
        <p className="text-2xl font-bold text-gray-800">8</p>
        <div className="flex items-center gap-2 mt-2">
          <div className="flex-1 bg-purple-100 rounded-full h-1.5">
            <div className="bg-purple-500 h-1.5 rounded-full" style={{ width: '65%' }}></div>
          </div>
          <span className="text-xs text-purple-600 font-medium">65%</span>
        </div>
      </div>

      {/* Yaklaşan Randevular */}
      <div className="bg-white p-4 rounded-lg shadow-md hover:shadow-xl transition-all">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-base text-gray-700 font-semibold">Yaklaşan Randevular</h3>
          <div className="p-2 bg-indigo-50 rounded-lg">
            <FaCalendarCheck className="text-indigo-500 text-lg" />
          </div>
        </div>
        <p className="text-2xl font-bold text-gray-800">{counts.appointment_count}</p>
        <div className="flex gap-3 mt-2">
          <span className="text-xs bg-indigo-50 text-indigo-600 px-2 py-1 rounded-md font-medium">Bugün: 5</span>
          <span className="text-xs bg-indigo-50 text-indigo-600 px-2 py-1 rounded-md font-medium">Yarın: 3</span>
        </div>
      </div>
    </div>
  );
}

export default StatisticsWidgets;
