import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaEdit, FaTrashAlt, FaSignOutAlt } from 'react-icons/fa';
import Navbar from '../../../Navbar';
import PetDetails from '../../klinikTakipServisi/PetList/PetDetails';

function PetListScreen() {
  const navigate = useNavigate();
  const [pets, setPets] = useState([]);
  const [newPet, setNewPet] = useState({
    name: '',
    owner_name: '',
    species: '',
    microchip_id: '',
    weight: '',
    complaint: '',
    diagnosis: '',
    gender: '',
  });

  const [showAddPetModal, setShowAddPetModal] = useState(false);

  useEffect(() => {
    const fetchPets = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await axios.get('https://api.dev1.fugesoft.com/api/owners', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const allPets = response.data.flatMap((owner) =>
          owner.pets.map((pet) => ({
            ...pet,
            ownerName: owner.name,
            ownerPhone: owner.phone_number,
            responsibleDoctor: pet.responsibleDoctor || 'Belirtilmemiş',
          }))
        );
        setPets(allPets);
      } catch (error) {
        console.error('Error fetching pets:', error);
      }
    };
  
    fetchPets();
  }, []);
  

  const handleRowClick = (petId) => {
    navigate(`/pet-details/${petId}`);
  };

  const handleAddPet = () => {
    const token = localStorage.getItem("authToken");
    axios
      .post('https://api.dev1.fugesoft.com/api/pets', newPet, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPets((prevPets) => [...prevPets, response.data]);
        setShowAddPetModal(false);
        setNewPet({
          name: '',
          owner_name: '',
          species: '',
          microchip_id: '',
          weight: '',
          complaint: '',
          diagnosis: '',
          gender: '',
        });
      })
      .catch((error) => console.error('Error adding pet:', error));
  };
  

  return (
    <div className="p-6">
      <Navbar title="Yatan Hasta Listesi" onBack={() => navigate(-1)} />

      <div>
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold mb-4">Yatan Hasta Listesi</h2>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded shadow"
            onClick={() => setShowAddPetModal(true)}
          >
            + Hayvan Ekle
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg border">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b text-center">Fotoğraf</th>
                <th className="px-4 py-2 border-b text-center">Hasta Adı</th>
                <th className="px-4 py-2 border-b text-center">Sahip Bilgileri</th>
                <th className="px-4 py-2 border-b text-center">Sorumlu Hekim</th>
                <th className="px-4 py-2 border-b text-center">Tanı</th>
                <th className="px-4 py-2 border-b text-center">İşlemler</th>
              </tr>
            </thead>
            <tbody>
              {pets.map((pet) => (
                <tr
                  key={pet._id}
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleRowClick(pet._id)}
                >
                  <td className="px-4 py-2 border-b text-center align-middle">
                    <img
                      src={pet.photo || 'https://via.placeholder.com/80'}
                      alt={pet.name}
                      className="h-9 w-9 rounded-full mx-auto"
                    />
                  </td>
                  <td className="px-4 py-2 border-b text-center align-middle">
                    {pet.name} <span className="text-gray-500">({pet.species})</span>
                  </td>
                  <td className="px-4 py-2 border-b text-center align-middle">
                    {pet.ownerName} - {pet.ownerPhone}
                  </td>
                  <td className="px-4 py-2 border-b text-center align-middle">
                    {pet.responsibleDoctor}
                  </td>
                  <td className="px-4 py-2 border-b text-center align-middle">
                    {pet.diagnosis || 'Yok'}
                  </td>
                  <td className="px-4 py-2 border-b text-center align-middle">
                    <button
                      className="text-green-500 hover:text-green-700 mx-1"
                      title="Taburcu Et"
                      onClick={(e) => {
                        e.stopPropagation();
                        alert(`Taburcu Et: ${pet.name}`);
                      }}
                    >
                      <FaSignOutAlt />
                    </button>
                    <button
                      className="text-blue-500 hover:text-blue-700 mx-1"
                      title="Düzenle"
                      onClick={(e) => {
                        e.stopPropagation();
                        alert(`Düzenle: ${pet.name}`);
                      }}
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="text-red-500 hover:text-red-700 mx-1"
                      title="Sil"
                      onClick={(e) => {
                        e.stopPropagation();
                        alert(`Sil: ${pet.name}`);
                      }}
                    >
                      <FaTrashAlt />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {showAddPetModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
              <h2 className="text-xl font-bold mb-4">Yeni Hayvan Ekle</h2>
              <form className="space-y-4">
                <input
                  type="text"
                  placeholder="Adı"
                  value={newPet.name}
                  onChange={(e) =>
                    setNewPet((prev) => ({ ...prev, name: e.target.value }))
                  }
                  className="w-full p-2 border rounded"
                />
                <input
                  type="text"
                  placeholder="Sahip Adı"
                  value={newPet.owner_name}
                  onChange={(e) =>
                    setNewPet((prev) => ({ ...prev, owner_name: e.target.value }))
                  }
                  className="w-full p-2 border rounded"
                />
                <input
                  type="text"
                  placeholder="Tür"
                  value={newPet.species}
                  onChange={(e) =>
                    setNewPet((prev) => ({ ...prev, species: e.target.value }))
                  }
                  className="w-full p-2 border rounded"
                />
                <input
                  type="text"
                  placeholder="Mikroçip ID"
                  value={newPet.microchip_id}
                  onChange={(e) =>
                    setNewPet((prev) => ({ ...prev, microchip_id: e.target.value }))
                  }
                  className="w-full p-2 border rounded"
                />
                <input
                  type="text"
                  placeholder="Kilo"
                  value={newPet.weight}
                  onChange={(e) =>
                    setNewPet((prev) => ({ ...prev, weight: e.target.value }))
                  }
                  className="w-full p-2 border rounded"
                />
                <textarea
                  placeholder="Şikayet"
                  value={newPet.complaint}
                  onChange={(e) =>
                    setNewPet((prev) => ({ ...prev, complaint: e.target.value }))
                  }
                  className="w-full p-2 border rounded"
                />
                <textarea
                  placeholder="Teşhis"
                  value={newPet.diagnosis}
                  onChange={(e) =>
                    setNewPet((prev) => ({ ...prev, diagnosis: e.target.value }))
                  }
                  className="w-full p-2 border rounded"
                />
                <input
                  type="text"
                  placeholder="Cinsiyet"
                  value={newPet.gender}
                  onChange={(e) =>
                    setNewPet((prev) => ({ ...prev, gender: e.target.value }))
                  }
                  className="w-full p-2 border rounded"
                />
                <button
                  type="button"
                  onClick={handleAddPet}
                  className="bg-green-500 text-white py-2 px-4 rounded"
                >
                  Kaydet
                </button>
                <button
                  type="button"
                  onClick={() => setShowAddPetModal(false)}
                  className="bg-gray-500 text-white py-2 px-4 rounded ml-2"
                >
                  İptal
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PetListScreen;
