import React, { useState } from "react";
import axios from "axios";
import ChatSidebar from "./ChatSidebar";
import MessageItem from "./MessageItem";
import { FaPaperPlane } from "react-icons/fa"; // Send button icon

function MessagingScreen() {
  const [selectedClient, setSelectedClient] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [unread, setUnread] = useState({});
  const [isTyping, setIsTyping] = useState(false);

  const token = localStorage.getItem("authToken"); // Retrieve token for API

  // Dummy messages for initial clients
  const dummyMessages = {
    2: [
      { text: "Merhaba veteriner bey, Bella’nın aşıları ne zaman yapılmalı?", sender: "client", timestamp: new Date() },
      { text: "Merhaba, Bella’nın aşıları önümüzdeki hafta yapılabilir.", sender: "clinic", timestamp: new Date() },
    ],
    3: [
      { text: "Max’in ameliyatı için ne yapmam gerekiyor?", sender: "client", timestamp: new Date() },
    ],
  };

  // Select a client and load their messages
  const selectClient = (client) => {
    setSelectedClient(client);
    setMessages(dummyMessages[client.id] || []);
    setUnread((prevUnread) => ({ ...prevUnread, [client.id]: false }));
  };

  // Handle sending a message and fetch AI response
  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    const message = {
      text: newMessage,
      sender: "clinic",
      timestamp: new Date(),
    };

    setMessages((prevMessages) => [...prevMessages, message]);
    setNewMessage("");

    // Fetch AI response
    setIsTyping(true);
    try {
      const response = await axios.post(
        "https://api.dev1.fugesoft.com/api/ai-assistant",
        null, // No request body, query is passed in params
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { query: newMessage },
        }
      );

      const aiResponse = response.data.suggestion || "Yanıt alınamadı.";
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: aiResponse, sender: "client", timestamp: new Date() },
      ]);
    } catch (error) {
      console.error("API Error:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Maalesef, şu anda yanıt veremiyorum.", sender: "client", timestamp: new Date() },
      ]);
    } finally {
      setIsTyping(false);
    }
  };

  return (
    <div className="flex h-4/5 bg-gray-50">
      {/* Sidebar */}
      <ChatSidebar selectClient={selectClient} unread={unread} />

      {/* Main Chat Section */}
      <div className="flex-1 flex flex-col bg-white shadow-md rounded-lg m-4">
        {/* Header */}
        {selectedClient ? (
          <>
            <div className="flex items-center justify-between p-4 bg-blue-600 text-white shadow-md rounded-t-lg">
              <div>
                <h2 className="text-2xl font-semibold">{selectedClient.name}</h2>
                <span className="text-sm">{selectedClient.petName}</span>
              </div>
            </div>

            {/* Chat Messages */}
            <div className="flex-1 p-4 overflow-y-auto bg-gray-100 space-y-3">
              {messages.map((message, index) => (
                <MessageItem key={index} message={message} />
              ))}
              {isTyping && (
                <div className="text-gray-500 text-sm italic">Yazıyor...</div>
              )}
            </div>

            {/* Input Section */}
            <div className="p-4 bg-gray-200 border-t flex items-center space-x-4">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Mesaj yaz..."
                className="flex-1 p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              <button
                onClick={handleSendMessage}
                className="p-3 bg-blue-500 text-white rounded-full shadow-md hover:bg-blue-600"
              >
                <FaPaperPlane size={20} />
              </button>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center flex-1">
            <h2 className="text-gray-500">Bir sohbet seçin...</h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default MessagingScreen;
