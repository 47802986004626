import React, { useState } from 'react';
import { FaUpload, FaSpinner, FaSave } from 'react-icons/fa';

const ORGAN_OPTIONS = ['KALP', 'AKCİĞER', 'BÖBREK', 'KARACİĞER', 'MİDE', 'BAĞIRSAK'];
const PET_TYPES = ['Köpek', 'Kedi', 'Kuş', 'Hamster'];

// Hastalık verilerini hiyerarşik yapıda oluştur
const DISEASES = {
  'İHBARI ZORUNLU HASTALIKLAR': [
    'AT VEBASI',
    'ATLARIN ENFEKSİYÖZ ANEMİSİ',
    'BRUCELLOZİS',
    'TÜBERKÜLOZ',
    'ŞARBON (Antrax)',
    'ŞAP',
    'SIĞIR VEBASI',
    'MAVİDİL',
    'KUDUZ',
    'NAKLEDİLEBİLİR SÜNGERİMSİ BEYİN HASTALIKLARI (BSE, FSE, Scrapie)',
    'SIĞIRLARIN NODÜLER EKZANTEMİ (Lumpy skin)',
    'EPİZOOTİC HAEMORRHAGİC DİSEASE (EHD)',
    'KOYUN KEÇİ ÇİÇEĞİ',
    'KOYUN KEÇİ VEBASI',
    'NEWCASTLE (Yalancı Tavuk Vebası)',
    'TAVUK VEBASI (Avıan İnfluenza-Kuş Gribi)',
    'ARILARIN AMERİKAN YAVRU ÇÜRÜKLÜĞÜ',
    'KÜÇÜK KOVAN KURDU (Aethina Tumida)',
    'TROPILAELAPS AKARI (Tropılaelaps Mıte)'
  ],
  'SOLUNUM SİSTEMİ HASTALIKLARI': [
    'BAKTERİYEL HASTALIKLAR',
    'VİRAL HASTALIKLAR',
    'PARAZİTER HASTALIKLAR',
    'ENFEKSİYÖZ OLMAYAN HASTALIKLAR',
    'OPERATİF MÜDAHALELER',
    'DİĞER'
  ],
  'SİNDİRİM SİSTEMİ HASTALIKLARI': [
    'BAKTERİYEL HASTALIKLAR',
    'VİRAL HASTALIKLAR',
    'PARAZİTER HASTALIKLAR',
    'PROTOZOER HASTALIKLAR',
    'ENFEKSİYÖZ OLMAYAN HASTALIKLAR',
    'OPERATİF MÜDAHALELER',
    'DİĞER'
  ],
  'ÜRO-GENİTAL SİSTEM HASTALIKLARI': [
    'BAKTERİYEL HASTALIKLAR',
    'VİRAL HASTALIKLAR',
    'ENFEKSİYÖZ OLMAYAN HASTALIKLAR',
    'DOĞUM',
    'OPERATİF MÜDAHALELER',
    'MASTİTİS',
    'İNFERTİLİTE (KISIRLIK)',
    'DİĞER'
  ],
  'SİNİR SİSTEMİ HASTALIKLARI': [
    'MERKEZİ SİNİR SİSTEMİ HASTALIKLARI',
    'PERİFER SİNİR SİSTEMİ HASTALIKLARI',
    'DİĞER'
  ],
  'KAS ve İSKELET SİSTEMİ HASTALIKLARI': [
    'AYAK HASTALIKLARI',
    'CERRAHİ MÜDAHALELER'
  ],
  'KARDİYO-VASKÜLER SİSTEM HASTALIKLARI': [
    'KALP',
    'DOLAŞIM SİSTEMİ HASTALIKLARI'
  ],
  'DERİ HASTALIKLARI': [
    'BAKTERİYEL HASTALIKLAR',
    'VİRAL HASTALIKLAR',
    'PARAZİTER HASTALIKLAR',
    'MANTAR HASTALIKLARI',
    'ENFEKSİYÖZ OLMAYAN HASTALIKLAR',
    'OPERATİF MÜDAHALELER',
    'DİĞER'
  ],
  'DUYU SİSTEMİ HASTALIKLARI': [
    'GÖZ HASTALIKLARI',
    'KULAK HASTALIKLARI'
  ],
  'METABOLİZMA HASTALIKLARI': [],
  'ARI HASTALIKLARI': [
    'PARAZİTER HASTALIKLAR',
    'BAKTERİYEL HASTALIKLAR'
  ],
  'BALIK HASTALIKLARI': [
    'BAKTERİYEL HASTALIKLAR',
    'PARAZİTER HASTALIKLAR',
    'DİĞER'
  ]
};

// İlaç listesi
const MEDICINES = [
  'ADVANTAGE MULTİ KEDİ',
  'ADVANTAGE MULTİ KÖPEK',
  'ALİZİN',
  'AMOKSİVET Tablet',
  'ANTİPAM',
  'APOQUEL 16 mg',
  'APOQUEL 3,6 mg',
  'APOQUEL 5.4 mg',
  'BA-SÜLFA',
  'BAVET MELOXİCAM',
  'BAYTRİL Flavour 150 mg',
  'BAYTRİL Flavour 50 mg',
  'BAYTRİL-K % 5',
  'BRAVECTO',
  'BRAVECTO DuAct',
  'BUTOMİDOR 10 MG/ML',
  'Cerenia',
  'CERENİA',
  'CLAMOXYL LA',
  'CORTAL %2',
  'DELAX',
  'DİÜRİL',
  'Endopet',
  'ENROTECH 50',
  'FİPROES KEDİ',
  'FİPROES KÖPEK',
  'FRONTLINE COMBO KEDİ',
  'FRONTLINE COMBO KÖPEK',
  'HİSTAVET',
  'KETA-CONTROL',
  'Ketasol %10',
  'Marbosan 10',
  'Marbosan 40',
  'METACAM 0,5 MG/ML',
  'Neptra',
  'NERVİT KOMPOZE',
  'NEXGARD COMBO',
  'NEXGARD SPECTRA >15-30 kg arası köpekler için',
  'NEXGARD SPECTRA >3,5-7,5 kg arası köpekler için',
  'NEXGARD SPECTRA >30-60 kg arası köpekler için',
  'NEXGARD SPECTRA >7,5-15 kg arası köpekler için',
  'NEXGARD SPECTRA 2-3,5 kg arası köpekler için',
  'ORIDERMYL',
  'PREVICOX 15,1-90 Kg',
  'PROMAZIN',
  'RİLEXİNE 300 mg',
  'SEDAPET',
  'SEMİNTRA 4 mg / ml',
  'SERESTO 38 cm',
  'SERESTO 70 cm',
  'STRONGHOLD %6',
  'SYNULOX',
  'SYNULOX PALATABLE 250 mg',
  'SYNULOX PALATABLE 50mg',
  'THERANEKRON D6',
  'VETMEDİN 1,25 mg',
  'VETMEDİN 5 MG'
];

function OnlineReceteScreen() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  
  const [form, setForm] = useState({
    item_to_click: '',
    item_to_select: '',
    chip_no: '',
    product_name: '',
    stock_quantity: '',
    application_quantity: '',
    day_period: '',
    application_period: '',
    application_span: ''
  });

  const [file, setFile] = useState(null);

  const [selectedMainCategory, setSelectedMainCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedMedicine, setSelectedMedicine] = useState('');

  // Ana kategoriler
  const mainCategories = Object.keys(DISEASES);

  // Seçili ana kategoriye ait alt kategoriler
  const subCategories = selectedMainCategory ? DISEASES[selectedMainCategory] : [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setFile(file);
      setError(null);
    } else {
      setError('Lütfen geçerli bir Excel dosyası (.xlsx) yükleyin');
      setFile(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      // Form validation
      const requiredFields = Object.entries(form);
      for (const [key, value] of requiredFields) {
        if (!value) {
          throw new Error(`Lütfen ${key.replace(/_/g, ' ')} alanını doldurun`);
        }
      }

      // TODO: API integration will be added here
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate API call
      
      setSuccess(true);
      setForm({
        item_to_click: '',
        item_to_select: '',
        chip_no: '',
        product_name: '',
        stock_quantity: '',
        application_quantity: '',
        day_period: '',
        application_period: '',
        application_span: ''
      });
      setFile(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-800">Online Reçete Girişi</h1>
        <p className="text-gray-600 mt-2">
          Reçete bilgilerini manuel girin veya Excel dosyası yükleyin
        </p>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}

      {success && (
        <div className="mb-4 p-3 bg-green-100 border border-green-400 text-green-700 rounded">
          Reçete başarıyla kaydedildi!
        </div>
      )}

      <div className="bg-white rounded-lg shadow-lg p-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Hastalık Kategorisi
              </label>
              <select
                value={selectedMainCategory}
                onChange={(e) => {
                  setSelectedMainCategory(e.target.value);
                  setSelectedSubCategory('');
                }}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Ana Kategori Seçin</option>
                {mainCategories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Alt Kategori
              </label>
              <select
                value={selectedSubCategory}
                onChange={(e) => setSelectedSubCategory(e.target.value)}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                disabled={!selectedMainCategory}
              >
                <option value="">Alt Kategori Seçin</option>
                {subCategories.map((subCategory) => (
                  <option key={subCategory} value={subCategory}>
                    {subCategory}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
               �laç
              </label>
              <select
                value={selectedMedicine}
                onChange={(e) => setSelectedMedicine(e.target.value)}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">İlaç Seçin</option>
                {MEDICINES.map((medicine, index) => (
                  <option key={index} value={medicine}>
                    {medicine}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Organ Seçimi
              </label>
              <select
                name="item_to_click"
                value={form.item_to_click}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Seçiniz</option>
                {ORGAN_OPTIONS.map(organ => (
                  <option key={organ} value={organ}>{organ}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Hayvan Türü
              </label>
              <select
                name="item_to_select"
                value={form.item_to_select}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Seçiniz</option>
                {PET_TYPES.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Çip Numarası
              </label>
              <input
                type="text"
                name="chip_no"
                value={form.chip_no}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Stok Miktarı
              </label>
              <input
                type="number"
                name="stock_quantity"
                value={form.stock_quantity}
                onChange={handleChange}
                min="1"
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Uygulama Miktarı
              </label>
              <input
                type="number"
                name="application_quantity"
                value={form.application_quantity}
                onChange={handleChange}
                min="1"
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Gün Periyodu
              </label>
              <input
                type="number"
                name="day_period"
                value={form.day_period}
                onChange={handleChange}
                min="1"
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Uygulama Periyodu
              </label>
              <input
                type="number"
                name="application_period"
                value={form.application_period}
                onChange={handleChange}
                min="1"
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Uygulama Süresi (Gün)
              </label>
              <input
                type="number"
                name="application_span"
                value={form.application_span}
                onChange={handleChange}
                min="1"
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
          </div>

          <div className="border-t border-gray-200 pt-6">
            <div className="flex items-center justify-between">
              <div className="flex-1 max-w-md">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Excel Dosyası Yükle (Opsiyonel)
                </label>
                <div className="flex items-center">
                  <label className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50">
                    <FaUpload className="mr-2 text-gray-400" />
                    <span className="text-sm text-gray-600">Dosya Seç</span>
                    <input
                      type="file"
                      className="hidden"
                      accept=".xlsx"
                      onChange={handleFileChange}
                    />
                  </label>
                  {file && (
                    <span className="ml-3 text-sm text-gray-500">
                      {file.name}
                    </span>
                  )}
                </div>
              </div>

              <button
                type="submit"
                disabled={loading}
                className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 flex items-center gap-2"
              >
                {loading ? (
                  <>
                    <FaSpinner className="animate-spin" />
                    Kaydediliyor...
                  </>
                ) : (
                  <>
                    <FaSave />
                    Kaydet
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OnlineReceteScreen; 