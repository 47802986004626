import React, { useState } from "react";
import {
  FaClipboardList,
  FaChartBar,
  FaProcedures,
  FaCommentDots,
  FaPlus,
  FaPrescriptionBottleAlt,
  FaTimes,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Sidebar({ activeModule, onModuleSelect }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  // Default visible modules
  const defaultModules = [
    { name: "Klinik Takip", icon: <FaClipboardList size={24} />, path: "/" },
    { name: "Online Reçete", icon: <FaPrescriptionBottleAlt size={24} />, path: "/online-recete" },
  ];

  // Additional modules that will be shown in the modal
  const additionalModules = [
    { name: "Finans Analizi", icon: <FaChartBar size={24} />, path: "/finance" },
    { name: "Yoğun Bakım Takibi", icon: <FaProcedures size={24} />, path: "/overview" },
    { name: "VetAssist", icon: <FaCommentDots size={24} />, path: "/vetassist" },
  ];

  const handleModuleClick = (module) => {
    onModuleSelect(module.name);
    navigate(module.path);
    setModalOpen(false);
  };

  return (
    <>
      {/* Sidebar */}
      <nav className="fixed left-0 top-1/2 transform -translate-y-1/2 bg-blue-900 text-white w-20 rounded-r-3xl flex flex-col items-center py-4 space-y-6 shadow-lg z-50">
        {/* Default Module Icons */}
        {defaultModules.map((module) => (
          <button
            key={module.name}
            onClick={() => handleModuleClick(module)}
            className={`w-14 h-14 flex items-center justify-center rounded-md transition-all duration-300 ${
              activeModule === module.name ? "bg-black" : "bg-blue-900"
            } hover:bg-black tooltip-container`}
          >
            {module.icon}
            <span className="tooltip">{module.name}</span>
          </button>
        ))}

        {/* Add Module Button */}
        <div className="mt-auto">
          <button
            onClick={() => setModalOpen(true)}
            className="w-14 h-14 flex items-center justify-center rounded-full bg-green-500 hover:bg-green-600 text-white shadow-lg transition-all duration-300 tooltip-container"
          >
            <FaPlus size={28} />
            <span className="tooltip">Modül Ekle</span>
          </button>
        </div>
      </nav>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={() => setModalOpen(false)}
        >
          <div
            className="bg-white w-[600px] p-8 rounded-xl shadow-2xl"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-800">Modül Ekle</h2>
              <button
                onClick={() => setModalOpen(false)}
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                <FaTimes size={24} />
              </button>
            </div>
            
            <div className="grid grid-cols-2 gap-4">
              {additionalModules.map((module) => (
                <button
                  key={module.name}
                  onClick={() => handleModuleClick(module)}
                  className="flex items-center space-x-4 p-4 rounded-lg bg-gray-50 hover:bg-gray-100 transition-all duration-200"
                >
                  <div className="w-12 h-12 flex items-center justify-center bg-blue-900 text-white rounded-lg">
                    {module.icon}
                  </div>
                  <span className="text-lg font-medium text-gray-700">{module.name}</span>
                </button>
              ))}
            </div>

            <p className="mt-6 text-gray-600 text-sm">
              İhtiyacınız olan modülleri sol menüye ekleyerek hızlıca erişebilirsiniz.
            </p>
          </div>
        </div>
      )}

      <style jsx>{`
        .tooltip-container {
          position: relative;
        }
        
        .tooltip {
          visibility: hidden;
          position: absolute;
          left: 100%;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba(0, 0, 0, 0.8);
          color: white;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 14px;
          white-space: nowrap;
          margin-left: 8px;
          z-index: 1000;
        }
        
        .tooltip-container:hover .tooltip {
          visibility: visible;
        }
      `}</style>
    </>
  );
}

export default Sidebar;
