import React, { useState, useEffect } from "react";
import {
  FaCalendarPlus,
  FaUserPlus,
  FaShoppingCart,
  FaFileAlt,
  FaRandom,
  FaSyncAlt,
  FaBell,
  FaUserClock,
  FaNotesMedical,
  FaPrescriptionBottleAlt
} from "react-icons/fa";
import NewPatientModal from "./ActionButtonModals/NewPatientModal";
import CustomerReminderModal from "./ActionButtonModals/CustomerReminderModal";
import SalesModal from "./ActionButtonModals/SalesModal";
import AppointmentModal from "../../../../components/AppointmentModal";
import TreatmentModal from "./ActionButtonModals/TreatmentModal";
import axios from "axios";
import moment from "moment";

function ActionButtons() {
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showNewPatientModal, setShowNewPatientModal] = useState(false);
  const [showCustomerReminderModal, setShowCustomerReminderModal] = useState(false);
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [showTreatmentModal, setShowTreatmentModal] = useState(false);
  const [newEvent, setNewEvent] = useState({
    petId: "",
    owner_name: "",
    pet_name: "",
    pet_species: "",
    pet_age: 0,
    assignedTo: "",
    description: "",
    title: "",
    date: moment().format("YYYY-MM-DD"),
    startTime: "",
    endTime: "",
  });

  const [pets, setPets] = useState([]);
  const [users, setUsers] = useState([]);
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    fetchPets();
    fetchUsers();
  }, []);

  const fetchPets = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/pets", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const petOptions = response.data.map((pet) => ({
        value: pet._id,
        label: `${pet.name} - ${pet.owner_name}`,
        pet_species: pet.species || "Bilinmiyor",
        pet_age: pet.age || 0,
      }));
      setPets(petOptions);
    } catch (error) {
      console.error("Error fetching pets:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userOptions = response.data.map((user) => ({
        value: user.id,
        label: `${user.name} ${user.surname} - ${user.role}`,
      }));
      setUsers(userOptions);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleAddEvent = async () => {
    try {
      const newAppointment = {
        petId: newEvent.petId,
        assignedTo: newEvent.assignedTo,
        owner_name: newEvent.owner_name,
        pet_name: newEvent.pet_name,
        pet_species: newEvent.pet_species,
        pet_age: newEvent.pet_age,
        description: newEvent.description,
        title: newEvent.title,
        date: newEvent.date,
        time: `${newEvent.startTime} - ${newEvent.endTime}`,
      };

      await axios.post("https://api.dev1.fugesoft.com/api/appointments", newAppointment, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setShowAppointmentModal(false);
    } catch (error) {
      console.error("Error adding appointment:", error);
    }
  };

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {/* Randevu Oluştur */}
      <button
        onClick={() => setShowAppointmentModal(true)}
        className="group bg-gradient-to-br from-blue-50 to-white p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="flex flex-col items-center">
          <div className="bg-blue-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaCalendarPlus className="text-blue-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Randevu Oluştur</h3>
        </div>
      </button>

      {/* Yeni Hasta */}
      <button
        onClick={() => setShowNewPatientModal(true)}
        className="group bg-gradient-to-br from-green-50 to-white p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="flex flex-col items-center">
          <div className="bg-green-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaUserPlus className="text-green-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Yeni Hasta</h3>
        </div>
      </button>

      {/* Satış Yap */}
      <button
        onClick={() => setShowSalesModal(true)}
        className="group bg-gradient-to-br from-purple-50 to-white p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="flex flex-col items-center">
          <div className="bg-purple-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaShoppingCart className="text-purple-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Satış Yap</h3>
        </div>
      </button>

      {/* Aylık Rapor */}
      <button
        onClick={() => alert("Aylık Rapor tıklandı!")}
        className="group bg-gradient-to-br from-yellow-50 to-white p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="flex flex-col items-center">
          <div className="bg-yellow-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaFileAlt className="text-yellow-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Aylık Rapor</h3>
        </div>
      </button>

      {/* İstem Oluştur */}
      <button
        onClick={() => alert("İstem Oluştur tıklandı!")}
        className="group bg-gradient-to-br from-red-50 to-white p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="flex flex-col items-center">
          <div className="bg-red-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaRandom className="text-red-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">İstem Oluştur</h3>
        </div>
      </button>

      {/* Order Oluştur */}
      <button
        onClick={() => alert("Order Oluştur tıklandı!")}
        className="group bg-gradient-to-br from-indigo-50 to-white p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="flex flex-col items-center">
          <div className="bg-indigo-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaPrescriptionBottleAlt className="text-indigo-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Order Oluştur</h3>
        </div>
      </button>

      {/* Tedavi Kayıt */}
      <button
        onClick={() => setShowTreatmentModal(true)}
        className="group bg-gradient-to-br from-teal-50 to-white p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="flex flex-col items-center">
          <div className="bg-teal-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaNotesMedical className="text-teal-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Tedavi Kayıt</h3>
        </div>
      </button>

      {/* Müşteri Hatırlatma */}
      <button
        onClick={() => setShowCustomerReminderModal(true)}
        className="group bg-gradient-to-br from-pink-50 to-white p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="flex flex-col items-center">
          <div className="bg-pink-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaUserClock className="text-pink-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Müşteri Hatırlatma</h3>
        </div>
      </button>

      {/* Modals */}
      {showAppointmentModal && (
        <AppointmentModal
          isOpen={showAppointmentModal}
          onRequestClose={() => setShowAppointmentModal(false)}
          pets={pets}
          users={users}
          newEvent={newEvent}
          setNewEvent={setNewEvent}
          handleAddEvent={handleAddEvent}
        />
      )}
      {showNewPatientModal && (
        <NewPatientModal
          isOpen={showNewPatientModal}
          onClose={() => setShowNewPatientModal(false)}
        />
      )}
      {showCustomerReminderModal && (
        <CustomerReminderModal
          isOpen={showCustomerReminderModal}
          onClose={() => setShowCustomerReminderModal(false)}
        />
      )}
      {showSalesModal && (
        <SalesModal
          isOpen={showSalesModal}
          onClose={() => setShowSalesModal(false)}
        />
      )}
      {showTreatmentModal && (
        <TreatmentModal
          isOpen={showTreatmentModal}
          onClose={() => setShowTreatmentModal(false)}
        />
      )}
    </div>
  );
}

export default ActionButtons;
