import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaHome,
  FaCalendarAlt,
  FaUsers,
  FaBoxes,
  FaHospital,
  FaSyringe,
  FaFileInvoiceDollar,
  FaMedkit,
} from "react-icons/fa";

const Navbar = ({ activeModule }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const moduleCategories = {
    "Klinik Takip": [
      { name: "Dashboard", path: "/", icon: <FaHome /> },
      { name: "Randevu Takvimi", path: "/randevutakvimi", icon: <FaCalendarAlt /> },
      { name: "Müşteri Listesi", path: "/müsteri-listesi", icon: <FaUsers /> },
      { name: "Stok Yönetimi", path: "/stok-yonetimi", icon: <FaBoxes /> },
      { name: "Klinik Detayları", path: "/klinik-detaylari", icon: <FaHospital /> },
      { name: "Aşı Takvimi", path: "/asi-takvimi", icon: <FaSyringe /> },
      { name: "Fatura", path: "/fatura", icon: <FaFileInvoiceDollar /> },
      { name: "İlaç & Supplement", path: "/klinik-takip/ilac-supplement", icon: <FaMedkit /> },
    ],
    "Finans Analizi": [
      { name: "Dashboard", path: "/finance", icon: <FaHome /> },
      { name: "Faturalar", path: "/fatura-yonetimi", icon: <FaFileInvoiceDollar /> },
      { name: "Gelir/Gider", path: "/gelir-gider", icon: <FaFileInvoiceDollar /> },
      { name: "Müşteri Listesi", path: "/müsteri-listesi", icon: <FaUsers /> },
    ],
    "Yoğun Bakım Takibi": [
      { name: "Dashboard", path: "/overview", icon: <FaHome /> },
      { name: "Hasta Takibi", path: "/hasta-takibi", icon: <FaUsers /> },
      { name: "Ekipman Yönetimi", path: "/ekipman-yonetimi", icon: <FaBoxes /> },
      { name: "Personel Planlama", path: "/personel-planlama", icon: <FaUsers /> },
    ],
    VetAssist: [
      
      { name: "Sohbetler", path: "/chat", icon: <FaUsers /> },
      { name: "Görevler", path: "/tasks", icon: <FaBoxes /> },
      { name: "Raporlar", path: "/reports", icon: <FaFileInvoiceDollar /> },
    ],
  };

  const categories = moduleCategories[activeModule] || [];

  return (
    <div className="bg-white border-b shadow-sm">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-center h-16 px-4">
          <nav className="flex space-x-1">
            {categories.map((category) => {
              const isActive = location.pathname === category.path;
              return (
                <button
                  key={category.name}
                  onClick={() => navigate(category.path)}
                  className={`
                    px-4 py-2 rounded-lg text-sm font-medium
                    flex items-center gap-2 transition-all duration-200
                    ${isActive 
                      ? 'bg-blue-50 text-blue-600' 
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    }
                  `}
                >
                  <span className={`${isActive ? 'text-blue-600' : 'text-gray-400'}`}>
                    {category.icon}
                  </span>
                  {category.name}
                </button>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
