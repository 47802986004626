import React from "react";
import StatisticsWidgets from "./dashboardScreen/statisticsWidgets/StatisticsWidgets";
import ActionButtons from "./dashboardScreen/actionButtons/ActionButtons";
import AppointmentsSchedule from "./dashboardScreen/appointmentSchedule/AppointmentsSchedule";
import ExpiringProducts from "./dashboardScreen/ExpiringProducts";
import KanbanBoard from "../yogunBakımServisi/KanbanBoard/KanbanBoard";

function OverviewScreen() {
  return (
    <div className="p-4">
      <div className="flex justify-between items-start space-x-4">
        <div className="flex-1">
          <div className="mt-6">
            <div className="mt-6 flex space-x-4">
              <div className="w-1/2">
                <StatisticsWidgets />
              </div>
              <div className="w-1/2">
                <ActionButtons />
              </div>
            </div>
            <div className="mt-6">
              <KanbanBoard />
            </div>
            <div className="mt-6 flex space-x-4">
              <div className="w-1/2">
                <ExpiringProducts />
              </div>
             
            </div>
           
          </div>
        </div>
        <div className="w-1/4">
          <AppointmentsSchedule />
        </div>
      </div>
    </div>
  );
}

export default OverviewScreen;
