import React, { useState, useEffect } from 'react';
import {
  FaTasks,
  FaUserCheck,
  FaMoneyBillWave,
  FaFileAlt,
  FaClipboardList,
  FaComments,
  FaWhatsapp,
  FaEdit,
  FaDoorOpen,
  FaPaw,
  FaUserCircle,
  FaPhone,
  FaMapMarkerAlt,
  FaMars,
  FaVenus,
  FaBirthdayCake,
  FaMicrochip,
  FaExclamationTriangle,
  FaWeight,
  FaSyringe,
  FaArrowLeft,
  FaCalendar,
  FaUpload,
  FaDownload,
  FaWallet,
  FaCreditCard,
  FaRegBell,
  FaSmile,
  FaAngry,
  FaMeh,
  FaRegFileAlt,
  FaRegClock,
  FaStethoscope
} from 'react-icons/fa';
import Modal from 'react-modal';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import KanbanBoard from '../../yogunBakımServisi/KanbanBoard/KanbanBoard';
import TreatmentHistoryModal from '../../yogunBakımServisi/YatanHastaListesi/TreatmentHistoryModal';

Modal.setAppElement('#root');

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0.5rem',
    padding: '2rem',
    maxWidth: '90vw',
    maxHeight: '90vh',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000
  }
};

function PetDetails() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalNotes, setModalNotes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [pet, setPet] = useState(null);
  const [editableFields, setEditableFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [newNote, setNewNote] = useState('');
  const [activeTab, setActiveTab] = useState('details');
  const [behaviorNote, setBehaviorNote] = useState('');
  const { petId } = useParams();
  const navigate = useNavigate();
  const [treatmentHistoryModalOpen, setTreatmentHistoryModalOpen] = useState(false);

  // Mock data
  const mockDocuments = [
    { id: 1, name: 'Röntgen Sonucu.pdf', type: 'xray', date: '2024-01-20', size: '2.4 MB' },
    { id: 2, name: 'Kan Tahlili.pdf', type: 'lab', date: '2024-01-15', size: '1.1 MB' },
    { id: 3, name: 'Aşı Kartı.pdf', type: 'vaccine', date: '2024-01-10', size: '0.8 MB' },
  ];

  const mockFinance = {
    totalDebt: 2500,
    lastPayment: { date: '2024-01-15', amount: 450 },
    payments: [
      { id: 1, date: '2024-01-15', amount: 450, description: 'Rutin kontrol' },
      { id: 2, date: '2024-01-10', amount: 850, description: 'Aşı uygulaması' },
    ],
  };

  const mockAppointments = [
    { id: 1, date: '2024-02-01', time: '14:30', type: 'Kontrol', status: 'upcoming' },
    { id: 2, date: '2024-01-20', time: '11:00', type: 'Aşı', status: 'completed' },
  ];

  const mockBehavior = {
    temperament: 'Sakin',
    aggressionLevel: 'Düşük',
    notes: [
      { id: 1, date: '2024-01-20', note: 'Diğer hayvanlarla iyi anlaşıyor', mood: 'positive' },
      { id: 2, date: '2024-01-15', note: 'Muayene sırasında gergin', mood: 'neutral' },
    ],
  };

  useEffect(() => {
    fetchPetDetails();
  }, [petId]);

  const fetchPetDetails = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("authToken");
      const response = await axios.get(`https://api.dev1.fugesoft.com/api/pets/${petId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPet(response.data);
      setEditableFields(response.data);
    } catch (error) {
      toast.error("Hasta bilgileri yüklenirken bir hata oluştu");
      console.error("Error fetching pet details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setEditableFields(pet);
    }
  };

  const handleInputChange = (field, value) => {
    setEditableFields(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("authToken");
      await axios.put(
        `https://api.dev1.fugesoft.com/api/pets/${petId}`,
        editableFields,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      await fetchPetDetails();
      setIsEditing(false);
      toast.success("Hasta bilgileri başarıyla güncellendi");
    } catch (error) {
      toast.error("Bilgiler güncellenirken bir hata oluştu");
      console.error("Error updating pet details:", error);
    }
  };

  const handleDischarge = () => {
    // TODO: Implement discharge logic
    toast.info('Taburcu işlemi gerçekleştirildi.');
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = pet.owner_phone?.replace(/\D/g, '');
    if (phoneNumber) {
      window.open(`https://wa.me/${phoneNumber}`, '_blank');
    }
  };

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent('');
    setModalNotes([]);
  };

  const addNote = () => {
    if (newNote.trim()) {
      setModalNotes(prev => [...prev, newNote]);
      setNewNote('');
    }
  };

  const categories = [
    {
      title: 'Muayeneler',
      icon: <FaStethoscope className="text-blue-500" />,
      bgColor: 'bg-blue-50',
      hoverColor: 'hover:bg-blue-100',
      onClick: () => setTreatmentHistoryModalOpen(true)
    },
    {
      title: 'İstek',
      icon: <FaTasks className="text-blue-500" />,
      onClick: () => navigate(`/istek-pet/${petId}`),
      bgColor: 'bg-blue-50',
      hoverColor: 'hover:bg-blue-100'
    },
    {
      title: 'Finans',
      icon: <FaMoneyBillWave className="text-yellow-500" />,
      bgColor: 'bg-yellow-50',
      hoverColor: 'hover:bg-yellow-100'
    },
    {
      title: 'Döküman',
      icon: <FaFileAlt className="text-purple-500" />,
      bgColor: 'bg-purple-50',
      hoverColor: 'hover:bg-purple-100'
    },
    {
      title: 'Order',
      icon: <FaClipboardList className="text-indigo-500" />,
      bgColor: 'bg-indigo-50',
      hoverColor: 'hover:bg-indigo-100'
    },
    {
      title: 'Görüşme',
      icon: <FaComments className="text-pink-500" />,
      bgColor: 'bg-pink-50',
      hoverColor: 'hover:bg-pink-100'
    },
  ];

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!pet) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <FaExclamationTriangle className="text-6xl text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-700">Hasta bulunamadı</h2>
        <p className="text-gray-500 mt-2">İstenen hasta bilgilerine ulaşılamadı</p>
        <button
          onClick={() => navigate('/pet-list')}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
        >
          Hasta Listesine Dön
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={() => navigate(-1)}
              className="text-gray-600 hover:text-gray-800"
            >
              <FaArrowLeft />
            </button>
            <h1 className="text-xl font-semibold text-gray-800">Hasta Detayı</h1>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Navigation Tabs */}
        <div className="flex space-x-4 mb-6 overflow-x-auto pb-2 mt-4">
          <button
            onClick={() => setActiveTab('details')}
            className={`px-6 py-3 rounded-lg flex items-center transition-all duration-200 ${
              activeTab === 'details' 
                ? 'bg-blue-600 text-white shadow-lg transform scale-105' 
                : 'bg-white hover:bg-blue-50 text-gray-700 shadow-sm hover:shadow'
            }`}
          >
            <FaPaw className="mr-2" />
            Detaylar
          </button>
          <button
            onClick={() => setActiveTab('documents')}
            className={`px-6 py-3 rounded-lg flex items-center transition-all duration-200 ${
              activeTab === 'documents' 
                ? 'bg-blue-600 text-white shadow-lg transform scale-105' 
                : 'bg-white hover:bg-blue-50 text-gray-700 shadow-sm hover:shadow'
            }`}
          >
            <FaRegFileAlt className="mr-2" />
            Dökümanlar
          </button>
          <button
            onClick={() => setActiveTab('finance')}
            className={`px-6 py-3 rounded-lg flex items-center transition-all duration-200 ${
              activeTab === 'finance' 
                ? 'bg-blue-600 text-white shadow-lg transform scale-105' 
                : 'bg-white hover:bg-blue-50 text-gray-700 shadow-sm hover:shadow'
            }`}
          >
            <FaWallet className="mr-2" />
            Finans
          </button>
          <button
            onClick={() => setActiveTab('appointments')}
            className={`px-6 py-3 rounded-lg flex items-center transition-all duration-200 ${
              activeTab === 'appointments' 
                ? 'bg-blue-600 text-white shadow-lg transform scale-105' 
                : 'bg-white hover:bg-blue-50 text-gray-700 shadow-sm hover:shadow'
            }`}
          >
            <FaRegClock className="mr-2" />
            Randevular
          </button>
          <button
            onClick={() => setActiveTab('notes')}
            className={`px-6 py-3 rounded-lg flex items-center transition-all duration-200 ${
              activeTab === 'notes' 
                ? 'bg-blue-600 text-white shadow-lg transform scale-105' 
                : 'bg-white hover:bg-blue-50 text-gray-700 shadow-sm hover:shadow'
            }`}
          >
            <FaClipboardList className="mr-2" />
            Notlar ve Yorumlar
          </button>
          <button
            onClick={() => setActiveTab('kanban')}
            className={`px-6 py-3 rounded-lg flex items-center transition-all duration-200 ${
              activeTab === 'kanban' 
                ? 'bg-blue-600 text-white shadow-lg transform scale-105' 
                : 'bg-white hover:bg-blue-50 text-gray-700 shadow-sm hover:shadow'
            }`}
          >
            <FaTasks className="mr-2" />
            Tedavi Süreci
          </button>
        </div>

        {/* Tab Content */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          {activeTab === 'details' && (
            <div>
              {/* Header Section */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex justify-between items-center mb-6">
                  <div className="flex items-center space-x-4">
                    <div className="p-3 bg-blue-50 rounded-full">
                      <FaPaw className="text-2xl text-blue-500" />
                    </div>
                    <div>
                      <h1 className="text-2xl font-bold text-gray-800">{pet.name}</h1>
                      <p className="text-gray-500">{pet.species} - {pet.breed}</p>
                    </div>
                  </div>
                  <div className="flex space-x-3">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="flex items-center space-x-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
                      onClick={isEditing ? handleSave : handleEditToggle}
                    >
                      <FaEdit className="text-sm" />
                      <span>{isEditing ? 'Kaydet' : 'Düzenle'}</span>
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="flex items-center space-x-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition"
                      onClick={handleDischarge}
                    >
                      <FaDoorOpen className="text-sm" />
                      <span>Taburcu Et</span>
                    </motion.button>
                  </div>
                </div>

                {/* Main Content Area - Flex Container */}
                <div className="flex gap-8">
                  {/* Left Side - Information Cards */}
                  <div className="w-2/3 space-y-6">
                    <div className="flex gap-8">
                      {/* Patient Information */}
                      <div className="w-1/2 space-y-6">
                        <h3 className="text-lg font-semibold text-gray-800 flex items-center space-x-2">
                          <FaPaw className="text-blue-500" />
                          <span>Hasta Bilgileri</span>
                        </h3>
                        
                        <div className="grid gap-4">
                          <InfoField
                            icon={pet.gender === 'Erkek' ? FaMars : FaVenus}
                            iconColor={pet.gender === 'Erkek' ? 'text-blue-500' : 'text-pink-500'}
                            label="Cinsiyet"
                            value={pet.gender}
                            isEditing={isEditing}
                            field="gender"
                            onChange={handleInputChange}
                            editableFields={editableFields}
                          />

                          <InfoField
                            icon={FaBirthdayCake}
                            iconColor="text-purple-500"
                            label="Doğum Tarihi"
                            value={pet.birth_date}
                            isEditing={isEditing}
                            field="birth_date"
                            type="date"
                            onChange={handleInputChange}
                            editableFields={editableFields}
                          />

                          <InfoField
                            icon={FaWeight}
                            iconColor="text-green-500"
                            label="Ağırlık"
                            value={`${pet.weight || ''} kg`}
                            isEditing={isEditing}
                            field="weight"
                            type="number"
                            onChange={handleInputChange}
                            editableFields={editableFields}
                          />

                          <InfoField
                            icon={FaMicrochip}
                            iconColor="text-gray-500"
                            label="Mikroçip ID"
                            value={pet.microchip_id}
                            isEditing={isEditing}
                            field="microchip_id"
                            onChange={handleInputChange}
                            editableFields={editableFields}
                          />

                          <InfoField
                            icon={FaSyringe}
                            iconColor="text-red-500"
                            label="Aşı Durumu"
                            value={pet.vaccination_status}
                            isEditing={isEditing}
                            field="vaccination_status"
                            onChange={handleInputChange}
                            editableFields={editableFields}
                          />

                          <InfoField
                            icon={FaExclamationTriangle}
                            iconColor="text-yellow-500"
                            label="Hasta Mizacı"
                            value={pet.hasta_mizaci}
                            isEditing={isEditing}
                            field="hasta_mizaci"
                            onChange={handleInputChange}
                            editableFields={editableFields}
                            multiline
                          />
                        </div>
                      </div>

                      {/* Owner Information */}
                      <div className="w-1/2 space-y-6">
                        <h3 className="text-lg font-semibold text-gray-800 flex items-center space-x-2">
                          <FaUserCircle className="text-green-500" />
                          <span>Sahip Bilgileri</span>
                        </h3>
                        
                        <div className="grid gap-4">
                          <InfoField
                            icon={FaUserCircle}
                            iconColor="text-green-500"
                            label="Adı Soyadı"
                            value={pet.owner_name}
                            isEditing={isEditing}
                            field="owner_name"
                            onChange={handleInputChange}
                            editableFields={editableFields}
                          />

                          <div className="flex items-center space-x-4">
                            <InfoField
                              icon={FaPhone}
                              iconColor="text-blue-500"
                              label="İletişim"
                              value={pet.owner_phone}
                              isEditing={isEditing}
                              field="owner_phone"
                              onChange={handleInputChange}
                              editableFields={editableFields}
                            />
                            {!isEditing && pet.owner_phone && (
                              <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                onClick={handleWhatsAppClick}
                                className="mt-6 text-green-500 hover:text-green-600"
                                title="WhatsApp'ta mesaj gönder"
                              >
                                <FaWhatsapp size={24} />
                              </motion.button>
                            )}
                          </div>

                          <InfoField
                            icon={FaMapMarkerAlt}
                            iconColor="text-red-500"
                            label="Adres"
                            value={pet.owner_address}
                            isEditing={isEditing}
                            field="owner_address"
                            onChange={handleInputChange}
                            editableFields={editableFields}
                            multiline
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Right Side - Category Buttons */}
                  <div className="w-1/3">
                    <div className="grid grid-cols-2 gap-4">
                      {categories.map((category, index) => (
                        <motion.div
                          key={index}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className={`${category.bgColor} ${category.hoverColor} p-6 rounded-xl shadow-sm cursor-pointer transition-all duration-200`}
                          onClick={category.onClick || (() => openModal(category.title))}
                        >
                          <div className="flex flex-col items-center space-y-3">
                            <div className="text-3xl">{category.icon}</div>
                            <span className="text-sm font-medium text-gray-700">{category.title}</span>
                          </div>
                        </motion.div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'documents' && (
            <div>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-bold">Ek Belgeler ve Dosyalar</h3>
                <button
                  onClick={() => toast.info('Dosya yükleme özelliği yakında eklenecek')}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center"
                >
                  <FaUpload className="mr-2" />
                  Dosya Yükle
                </button>
              </div>
              <div className="space-y-4">
                {mockDocuments.map(doc => (
                  <div key={doc.id} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-center">
                      <FaRegFileAlt className="text-gray-500 mr-3" />
                      <div>
                        <p className="font-semibold">{doc.name}</p>
                        <p className="text-sm text-gray-500">{doc.date} - {doc.size}</p>
                      </div>
                    </div>
                    <button
                      onClick={() => toast.info('İndirme özelliği yakında eklenecek')}
                      className="text-blue-500 hover:text-blue-600"
                    >
                      <FaDownload />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeTab === 'finance' && (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="bg-red-50 p-4 rounded-lg">
                  <h4 className="text-lg font-semibold text-red-700 mb-2">Toplam Borç</h4>
                  <p className="text-2xl font-bold text-red-600">{mockFinance.totalDebt} TL</p>
                </div>
                <div className="bg-green-50 p-4 rounded-lg">
                  <h4 className="text-lg font-semibold text-green-700 mb-2">Son Ödeme</h4>
                  <p className="text-2xl font-bold text-green-600">{mockFinance.lastPayment.amount} TL</p>
                  <p className="text-sm text-green-600">{mockFinance.lastPayment.date}</p>
                </div>
              </div>
              <div className="space-y-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-bold">Ödeme Geçmişi</h3>
                  <button
                    onClick={() => toast.info('Ödeme alma özelliği yakında eklenecek')}
                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                  >
                    <FaCreditCard className="mr-2" />
                    Ödeme Al
                  </button>
                </div>
                {mockFinance.payments.map(payment => (
                  <div key={payment.id} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
                    <div>
                      <p className="font-semibold">{payment.description}</p>
                      <p className="text-sm text-gray-500">{payment.date}</p>
                    </div>
                    <span className="font-bold text-green-600">{payment.amount} TL</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeTab === 'appointments' && (
            <div>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-bold">Randevular ve Hatırlatıcılar</h3>
                <button
                  onClick={() => toast.info('Randevu planlama özelliği yakında eklenecek')}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center"
                >
                  <FaCalendar className="mr-2" />
                  Randevu Planla
                </button>
              </div>
              <div className="space-y-4">
                {mockAppointments.map(apt => (
                  <div key={apt.id} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-center">
                      <div className={`w-3 h-3 rounded-full mr-4 ${
                        apt.status === 'upcoming' ? 'bg-yellow-400' : 'bg-green-400'
                      }`} />
                      <div>
                        <p className="font-semibold">{apt.type}</p>
                        <p className="text-sm text-gray-500">{apt.date} - {apt.time}</p>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      {apt.status === 'upcoming' && (
                        <>
                          <button
                            onClick={() => toast.info('Düzenleme özelliği yakında eklenecek')}
                            className="text-blue-500 hover:text-blue-600"
                          >
                            <FaEdit />
                          </button>
                          <button
                            onClick={() => toast.info('Hatırlatıcı özelliği yakında eklenecek')}
                            className="text-yellow-500 hover:text-yellow-600"
                          >
                            <FaRegBell />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeTab === 'notes' && (
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-bold mb-4">Veteriner Notları</h3>
                <textarea
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  placeholder="Yeni not ekle..."
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  rows="3"
                />
                <button
                  onClick={() => {
                    toast.success('Not eklendi');
                    setNewNote('');
                  }}
                  className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                >
                  Not Ekle
                </button>
              </div>

              <div>
                <h3 className="text-xl font-bold mb-4">Mizaç ve Davranış</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div className="bg-green-50 p-4 rounded-lg flex items-center">
                    <FaSmile className="text-green-500 mr-2" />
                    <div>
                      <p className="font-semibold">Mizaç</p>
                      <p className="text-sm text-gray-600">{mockBehavior.temperament}</p>
                    </div>
                  </div>
                  <div className="bg-red-50 p-4 rounded-lg flex items-center">
                    <FaAngry className="text-red-500 mr-2" />
                    <div>
                      <p className="font-semibold">Saldırganlık Seviyesi</p>
                      <p className="text-sm text-gray-600">{mockBehavior.aggressionLevel}</p>
                    </div>
                  </div>
                </div>
                <textarea
                  value={behaviorNote}
                  onChange={(e) => setBehaviorNote(e.target.value)}
                  placeholder="Davranış notu ekle..."
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  rows="3"
                />
                <button
                  onClick={() => {
                    toast.success('Davranış notu eklendi');
                    setBehaviorNote('');
                  }}
                  className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                >
                  Davranış Notu Ekle
                </button>
                <div className="mt-4 space-y-3">
                  {mockBehavior.notes.map(note => (
                    <div key={note.id} className="p-4 bg-gray-50 rounded-lg">
                      <div className="flex justify-between items-start mb-2">
                        <div className="flex items-center">
                          {note.mood === 'positive' && <FaSmile className="text-green-500 mr-2" />}
                          {note.mood === 'neutral' && <FaMeh className="text-yellow-500 mr-2" />}
                          {note.mood === 'negative' && <FaAngry className="text-red-500 mr-2" />}
                        </div>
                        <span className="text-sm text-gray-500">{note.date}</span>
                      </div>
                      <p className="text-gray-700">{note.note}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {activeTab === 'kanban' && (
            <div className="bg-white rounded-lg shadow-lg p-6 mt-6">
              <h3 className="text-xl font-bold mb-4 flex items-center">
                <FaTasks className="mr-2" />
                Tedavi Süreci
              </h3>
              <KanbanBoard showNavbar={false} petId={petId} />
            </div>
          )}
        </div>
      </div>
      {/* Treatment History Modal */}
      <TreatmentHistoryModal
        isOpen={treatmentHistoryModalOpen}
        onClose={() => setTreatmentHistoryModalOpen(false)}
        petId={petId}
      />
    </div>
  );
}

// Info Field Component
const InfoField = ({
  icon: Icon,
  iconColor,
  label,
  value,
  isEditing,
  field,
  onChange,
  editableFields,
  type = 'text',
  multiline = false
}) => (
  <div className="relative">
    <div className="flex items-center space-x-2 text-sm text-gray-500 mb-1">
      <Icon className={iconColor} />
      <span>{label}</span>
    </div>
    {isEditing ? (
      multiline ? (
        <textarea
          value={editableFields[field] || ''}
          onChange={(e) => onChange(field, e.target.value)}
          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
          rows="3"
        />
      ) : (
        <input
          type={type}
          value={editableFields[field] || ''}
          onChange={(e) => onChange(field, e.target.value)}
          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
        />
      )
    ) : (
      <p className="font-medium text-gray-800">{value || 'Belirtilmedi'}</p>
    )}
  </div>
);

export default PetDetails;
