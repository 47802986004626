import React, { useState } from "react";
import SummaryCards from "./SummaryCards";
import RevenueExpenseChart from "./RevenueExpenseChart";
import PieChart from "./PieChart";
import StockLevelChart from "./StockLevelChart"; // Yeni grafik
import ReorderTable from "./ReorderTable"; // Yeni tablo
import Filters from "./Filters";
import ExportButtons from "./ExportButtons";

function FinanceAnalyzeScreen() {
  const [filter, setFilter] = useState({ dateRange: "thisMonth", category: "all" });

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <Filters filter={filter} setFilter={setFilter} />

      {/* Kartlar */}
      <SummaryCards />

      {/* Grafikler */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6">
        <RevenueExpenseChart filter={filter} />
        <PieChart filter={filter} />
        <StockLevelChart filter={filter} />
      </div>

      {/* Stok Takip Tablosu */}
      <div className="mt-6">
        <ReorderTable filter={filter} />
      </div>

      {/* Export Butonları */}
      <div className="mt-4 flex justify-end">
        <ExportButtons />
      </div>
    </div>
  );
}

export default FinanceAnalyzeScreen;
