import React from "react";

function ReorderTable({ filter }) {
  const reorderData = [
    { product: "Mama A", stock: 5, reorderDate: "12.12.2024" },
    { product: "İlaç B", stock: 2, reorderDate: "15.12.2024" },
    { product: "Mama C", stock: 8, reorderDate: "20.12.2024" },
  ];

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h3 className="text-lg font-semibold mb-4">Yeniden Sipariş Gereken Ürünler</h3>
      <table className="w-full table-auto text-left border-collapse">
        <thead>
          <tr>
            <th className="border-b py-2 px-4">Ürün</th>
            <th className="border-b py-2 px-4">Stok Miktarı</th>
            <th className="border-b py-2 px-4">Önerilen Sipariş Tarihi</th>
          </tr>
        </thead>
        <tbody>
          {reorderData.map((item, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="py-2 px-4">{item.product}</td>
              <td className="py-2 px-4">{item.stock}</td>
              <td className="py-2 px-4">{item.reorderDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ReorderTable;
