import React, { useEffect, useRef } from "react";
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Tooltip } from "chart.js";

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip);

function StockLevelChart({ filter }) {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["Mama A", "Mama B", "İlaç X", "İlaç Y"],
        datasets: [
          {
            label: "Stok Miktarı",
            data: [20, 15, 8, 5],
            backgroundColor: ["#60A5FA", "#34D399", "#FBBF24", "#F87171"],
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${context.raw} adet`;
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Ürünler",
            },
          },
          y: {
            title: {
              display: true,
              text: "Miktar (adet)",
            },
            beginAtZero: true,
          },
        },
      },
    });

    return () => chart.destroy(); // Grafik yok ediliyor.
  }, [filter]);

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h3 className="text-lg font-semibold mb-4">Veteriner Stok Seviyeleri</h3>
      <canvas ref={chartRef}></canvas>
    </div>
  );
}

export default StockLevelChart;
