import React from "react";

function InvoiceTable({ onSelectInvoice }) {
  const invoices = [
    {
      id: 1,
      customer: "Veteriner Klinik A",
      date: "2024-12-01",
      status: "Ödenmiş",
      total: "₺4,500",
    },
    {
      id: 2,
      customer: "Veteriner Klinik B",
      date: "2024-12-05",
      status: "Bekliyor",
      total: "₺3,200",
    },
    {
      id: 3,
      customer: "Veteriner Klinik C",
      date: "2024-12-07",
      status: "Gecikmiş",
      total: "₺2,100",
    },
  ];

  return (
    <table className="w-full bg-white rounded-lg shadow-md">
      <thead className="bg-gray-100">
        <tr>
          <th className="px-4 py-2">Tarih</th>
          <th className="px-4 py-2">Müşteri</th>
          <th className="px-4 py-2">Durum</th>
          <th className="px-4 py-2">Toplam</th>
          <th className="px-4 py-2">Aksiyon</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice) => (
          <tr
            key={invoice.id}
            className="hover:bg-gray-50 cursor-pointer"
            onClick={() => onSelectInvoice(invoice)}
          >
            <td className="px-4 py-2">{invoice.date}</td>
            <td className="px-4 py-2">{invoice.customer}</td>
            <td
              className={`px-4 py-2 ${
                invoice.status === "Ödenmiş"
                  ? "text-green-500"
                  : invoice.status === "Bekliyor"
                  ? "text-yellow-500"
                  : "text-red-500"
              }`}
            >
              {invoice.status}
            </td>
            <td className="px-4 py-2">{invoice.total}</td>
            <td className="px-4 py-2 text-blue-500">Detay</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default InvoiceTable;
