import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { FaPlus, FaPencilAlt, FaTrash, FaBell, FaCheck } from 'react-icons/fa';

const mockPets = [
  { id: 1, name: 'Max', type: 'Köpek', breed: 'Golden Retriever', owner: 'Ahmet Yılmaz' },
  { id: 2, name: 'Luna', type: 'Kedi', breed: 'British Shorthair', owner: 'Ayşe Demir' },
];

const mockVaccines = [
  { id: 1, name: 'Kuduz Aşısı', period: '12 ay' },
  { id: 2, name: 'DHPP', period: '12 ay' },
  { id: 3, name: 'Lyme', period: '12 ay' },
  { id: 4, name: 'Bordetella', period: '6 ay' },
];

const AsiTakvimiScreen = () => {
  const [vaccinations, setVaccinations] = useState([
    {
      id: 1,
      petId: 1,
      vaccineId: 1,
      date: new Date().toISOString().split('T')[0],
      nextDate: new Date(new Date().setMonth(new Date().getMonth() + 12)).toISOString().split('T')[0],
      status: 'Beklemede',
    },
  ]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedPet, setSelectedPet] = useState('');
  const [selectedVaccine, setSelectedVaccine] = useState('');

  const handleAddVaccination = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDate(new Date().toISOString().split('T')[0]);
    setSelectedPet('');
    setSelectedVaccine('');
  };

  const handleSaveVaccination = () => {
    const vaccine = mockVaccines.find(v => v.id === selectedVaccine);
    const monthsToAdd = parseInt(vaccine.period);
    
    const nextDate = new Date(selectedDate);
    nextDate.setMonth(nextDate.getMonth() + monthsToAdd);
    
    const newVaccination = {
      id: vaccinations.length + 1,
      petId: selectedPet,
      vaccineId: selectedVaccine,
      date: selectedDate,
      nextDate: nextDate.toISOString().split('T')[0],
      status: 'Beklemede',
    };

    setVaccinations([...vaccinations, newVaccination]);
    handleCloseDialog();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Tamamlandı':
        return 'success';
      case 'Beklemede':
        return 'warning';
      case 'Gecikmiş':
        return 'error';
      default:
        return 'default';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getPetById = (id) => mockPets.find(pet => pet.id === id);
  const getVaccineById = (id) => mockVaccines.find(vaccine => vaccine.id === id);

  return (
    <Box className="p-6 bg-gray-50 min-h-screen">
      <Card className="mb-6">
        <CardContent>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" className="text-gray-800 font-semibold">
                Aşı Takvimi
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FaPlus />}
                onClick={handleAddVaccination}
                className="bg-blue-600 hover:bg-blue-700"
              >
                Yeni Aşı Ekle
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid container spacing={3}>
        {vaccinations.map((vaccination) => {
          const pet = getPetById(vaccination.petId);
          const vaccine = getVaccineById(vaccination.vaccineId);
          
          return (
            <Grid item xs={12} md={6} lg={4} key={vaccination.id}>
              <Card className="hover:shadow-md transition-shadow duration-200">
                <CardContent>
                  <Box className="flex justify-between items-start mb-4">
                    <Typography variant="h6" className="font-semibold">
                      {pet?.name}
                    </Typography>
                    <Chip
                      label={vaccination.status}
                      color={getStatusColor(vaccination.status)}
                      size="small"
                    />
                  </Box>
                  
                  <Box className="space-y-2 text-gray-600">
                    <Box className="flex items-center justify-between">
                      <Typography variant="body2">Hayvan Türü:</Typography>
                      <Typography variant="body2" className="font-medium">
                        {pet?.type}
                      </Typography>
                    </Box>
                    
                    <Box className="flex items-center justify-between">
                      <Typography variant="body2">Aşı:</Typography>
                      <Typography variant="body2" className="font-medium">
                        {vaccine?.name}
                      </Typography>
                    </Box>
                    
                    <Box className="flex items-center justify-between">
                      <Typography variant="body2">Son Aşı Tarihi:</Typography>
                      <Typography variant="body2" className="font-medium">
                        {formatDate(vaccination.date)}
                      </Typography>
                    </Box>
                    
                    <Box className="flex items-center justify-between">
                      <Typography variant="body2">Sonraki Aşı Tarihi:</Typography>
                      <Typography variant="body2" className="font-medium">
                        {formatDate(vaccination.nextDate)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="flex justify-end space-x-2 mt-4">
                    <IconButton
                      size="small"
                      className="text-blue-600 hover:text-blue-700"
                    >
                      <FaPencilAlt size={14} />
                    </IconButton>
                    <IconButton
                      size="small"
                      className="text-green-600 hover:text-green-700"
                    >
                      <FaCheck size={14} />
                    </IconButton>
                    <IconButton
                      size="small"
                      className="text-red-600 hover:text-red-700"
                    >
                      <FaTrash size={14} />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Yeni Aşı Ekle</DialogTitle>
        <DialogContent>
          <Box className="space-y-4 mt-2">
            <FormControl fullWidth>
              <InputLabel>Hayvan</InputLabel>
              <Select
                value={selectedPet}
                label="Hayvan"
                onChange={(e) => setSelectedPet(e.target.value)}
              >
                {mockPets.map((pet) => (
                  <MenuItem key={pet.id} value={pet.id}>
                    {pet.name} - {pet.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Aşı</InputLabel>
              <Select
                value={selectedVaccine}
                label="Aşı"
                onChange={(e) => setSelectedVaccine(e.target.value)}
              >
                {mockVaccines.map((vaccine) => (
                  <MenuItem key={vaccine.id} value={vaccine.id}>
                    {vaccine.name} ({vaccine.period})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              type="date"
              label="Aşı Tarihi"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>İptal</Button>
          <Button
            onClick={handleSaveVaccination}
            variant="contained"
            disabled={!selectedPet || !selectedVaccine || !selectedDate}
          >
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AsiTakvimiScreen;
