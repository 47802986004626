import React from "react";
import Modal from "react-modal";
import Select from "react-select";

function AppointmentModal({
  isOpen,
  onRequestClose,
  pets,
  users,
  newEvent,
  setNewEvent,
  handleAddEvent,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <h2 className="text-xl font-bold mb-4">Yeni Randevu Ekle</h2>
      <form>
        <label className="block mb-2">Pet ve Sahip Adı</label>
        <Select
          options={pets}
          onChange={(selectedOption) =>
            setNewEvent((prev) => ({
              ...prev,
              petId: selectedOption.value,
              owner_name: selectedOption.label.split(" - ")[1],
              pet_name: selectedOption.label.split(" - ")[0],
              pet_species: selectedOption.pet_species,
              pet_age: selectedOption.pet_age,
              title: `${selectedOption.label.split(" - ")[1]} - Randevu`,
            }))
          }
          placeholder="Evcil hayvanı ve sahibini seç..."
          value={pets.find((pet) => pet.value === newEvent.petId) || null}
          className="mb-4"
        />

        <label className="block mb-2">Çalışan</label>
        <Select
          options={users}
          onChange={(selectedOption) =>
            setNewEvent((prev) => ({
              ...prev,
              assignedTo: selectedOption?.value || "",
              color: selectedOption?.color || "blue"
            }))
          }
          placeholder="Çalışan seçiniz..."
          value={users.find((user) => user.value === newEvent.assignedTo) || null}
          className="mb-4"
          isSearchable={true}
          isClearable={true}
          noOptionsMessage={() => "Çalışan bulunamadı"}
        />

        <label className="block mb-2">Başlık</label>
        <input
          type="text"
          value={newEvent.title}
          onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
          className="w-full p-2 border rounded mb-4"
        />

        <label className="block mb-2">Açıklama</label>
        <input
          type="text"
          value={newEvent.description}
          onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
          className="w-full p-2 border rounded mb-4"
        />

        <label className="block mb-2">Tarih</label>
        <input
          type="date"
          value={newEvent.date}
          onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
          className="w-full p-2 border rounded mb-4"
        />

        <label className="block mb-2">Başlangıç Saati</label>
        <input
          type="time"
          value={newEvent.startTime || ""}
          onChange={(e) => setNewEvent({ 
            ...newEvent, 
            startTime: e.target.value,
            time: e.target.value // Keep API compatibility
          })}
          className="w-full p-2 border rounded mb-4"
        />

        <label className="block mb-2">Bitiş Saati</label>
        <input
          type="time"
          value={newEvent.endTime || ""}
          onChange={(e) => setNewEvent({ ...newEvent, endTime: e.target.value })}
          className="w-full p-2 border rounded mb-4"
        />

        <div className="flex justify-end">
          <button
            type="button"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
            onClick={handleAddEvent}
          >
            Ekle
          </button>
          <button
            type="button"
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
            onClick={onRequestClose}
          >
            İptal
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default AppointmentModal;
