import React from "react";
import moment from "moment";

function MessageItem({ message }) {
  const isClient = message.sender === "client";

  return (
    <div
      className={`flex ${
        isClient ? "justify-start" : "justify-end"
      } mb-2`}
    >
      <div
        className={`w-auto max-w-2xl px-4 py-3 rounded-xl shadow ${
          isClient
            ? "bg-gray-200 text-gray-800"
            : "bg-blue-500 text-white"
        }`}
        style={{ wordWrap: "break-word" }}
      >
        <p className="whitespace-pre-wrap leading-6">{message.text}</p>
        <span
          className={`block mt-2 text-xs ${
            isClient ? "text-gray-600" : "text-blue-200"
          } text-right`}
        >
          {moment(message.timestamp).format("HH:mm")}
        </span>
      </div>
    </div>
  );
}

export default MessageItem;
