import React, { useEffect, useRef } from 'react';
import {
  Chart,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  Tooltip,
  Legend,
} from 'chart.js';

Chart.register(LineController, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

function RevenueExpenseChart({ filter }) {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs'],
        datasets: [
          {
            label: 'Gelir',
            data: [4000, 4500, 5000, 5500, 6000],
            borderColor: '#34D399',
            tension: 0.4,
            fill: false,
          },
          {
            label: 'Gider',
            data: [3000, 3200, 3100, 3300, 3400],
            borderColor: '#F87171',
            tension: 0.4,
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            mode: 'index',
            intersect: false,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Aylar',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Tutar (₺)',
            },
          },
        },
      },
    });

    return () => {
      chart.destroy(); // Çakışmayı önlemek için grafik yok ediliyor.
    };
  }, [filter]);

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h3 className="text-lg font-semibold mb-4">Gelir ve Gider Trendleri</h3>
      <canvas ref={chartRef}></canvas>
    </div>
  );
}

export default RevenueExpenseChart;
