import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Select from 'react-select';

Modal.setAppElement('#root');

function TreatmentModal({ isOpen, onClose, petId: initialPetId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showPrescription, setShowPrescription] = useState(false);
  const [pets, setPets] = useState([]);
  const [vets, setVets] = useState([]);
  const [selectedPet, setSelectedPet] = useState(null);
  const [selectedVet, setSelectedVet] = useState(null);

  const [form, setForm] = useState({
    petId: initialPetId || "",
    veterinarian: "",
    treatment_date: new Date().toISOString().split('T')[0],
    treatment_type: "",
    symptoms: "",
    pre_diagnosis: "",
    anamnez: "",
    weight: "",
    body_temperature: "",
    general_condition: "NORMAL",
    Solunum_rate: "",
    pulse: "",
    capillary_refill_time: "",
    lymph_nodes: "",
    mucous_membranes: "",
    coat_structure: "",
    dehydration_level: "",
    prescription_type: "",
    serial_number: "",
    book_number: "",
    sequence_number: "",
    e_prescription_number: "",
    approved: false,
    printed: false,
    prescription_notes: "",
    prescription_food: "",
    amount: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        
        // Fetch pets
        const petsResponse = await axios.get('https://api.dev1.fugesoft.com/api/pets', {
          headers: { Authorization: `Bearer ${token}` }
        });
        const petOptions = petsResponse.data.map(pet => ({
          value: pet._id,
          label: `${pet.name} - ${pet.owner_name}`
        }));
        setPets(petOptions);

        // If initialPetId exists, set the selected pet
        if (initialPetId) {
          const initialPet = petOptions.find(p => p.value === initialPetId);
          if (initialPet) {
            setSelectedPet(initialPet);
          }
        }

        // Fetch vets
        const vetsResponse = await axios.get('https://api.dev1.fugesoft.com/api/vets', {
          headers: { Authorization: `Bearer ${token}` }
        });
        const vetOptions = vetsResponse.data.map(vet => ({
          value: vet._id,
          label: vet.name
        }));
        setVets(vetOptions);
      } catch (err) {
        setError('Veriler yüklenirken bir hata oluştu');
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, [initialPetId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handlePetChange = (selectedOption) => {
    setSelectedPet(selectedOption);
    setForm(prev => ({
      ...prev,
      petId: selectedOption?.value || ""
    }));
  };

  const handleVetChange = (selectedOption) => {
    setSelectedVet(selectedOption);
    setForm(prev => ({
      ...prev,
      veterinarian: selectedOption?.label || ""
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedPet) {
      setError('Lütfen bir hasta seçin');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('authToken');
      const submitData = {
        ...form,
        petId: selectedPet.value,
        weight: form.weight ? parseFloat(form.weight) : 0,
        body_temperature: form.body_temperature ? parseFloat(form.body_temperature) : 0,
        Solunum_rate: form.Solunum_rate ? parseInt(form.Solunum_rate) : 0,
        pulse: form.pulse ? parseInt(form.pulse) : 0,
        capillary_refill_time: form.capillary_refill_time ? parseInt(form.capillary_refill_time) : 0,
        amount: form.amount ? parseFloat(form.amount) : 0
      };

      await axios.post('https://api.dev1.fugesoft.com/api/treatments', submitData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      onClose();
    } catch (err) {
      setError(err.response?.data?.message || 'Bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: '42px',
      borderRadius: '0.375rem',
      borderColor: '#e5e7eb',
      '&:hover': {
        borderColor: '#3b82f6'
      }
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999
    })
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-xl w-[90%] max-w-4xl max-h-[90vh] overflow-y-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Yeni Muayene Kaydı</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <FaTimes size={24} />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Temel Bilgiler */}
          <div className="bg-white rounded-lg border p-4 space-y-4">
            <h3 className="text-lg font-semibold text-gray-700 mb-4">Temel Bilgiler</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Hasta Seçimi
                </label>
                <Select
                  value={selectedPet}
                  onChange={handlePetChange}
                  options={pets}
                  isSearchable
                  placeholder="Hasta veya sahip adı ile arayın..."
                  noOptionsMessage={() => "Hasta bulunamadı"}
                  styles={customStyles}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Veteriner Hekim
                </label>
                <Select
                  value={selectedVet}
                  onChange={handleVetChange}
                  options={vets}
                  isSearchable
                  placeholder="Veteriner hekim seçin..."
                  noOptionsMessage={() => "Veteriner hekim bulunamadı"}
                  styles={customStyles}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Muayene Tarihi
                </label>
                <input
                  type="date"
                  name="treatment_date"
                  value={form.treatment_date}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Tedavi Türü
                </label>
                <input
                  type="text"
                  name="treatment_type"
                  value={form.treatment_type}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Semptomlar
                </label>
                <textarea
                  name="symptoms"
                  value={form.symptoms}
                  onChange={handleChange}
                  rows="3"
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Ön Teşhis
                </label>
                <textarea
                  name="pre_diagnosis"
                  value={form.pre_diagnosis}
                  onChange={handleChange}
                  rows="3"
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Anamnez
                </label>
                <textarea
                  name="anamnez"
                  value={form.anamnez}
                  onChange={handleChange}
                  rows="3"
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          {/* Fiziksel Muayene */}
          <div className="bg-white rounded-lg border p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-gray-700">Fiziksel Muayene</h3>
              <button
                type="button"
                onClick={() => setShowAdvanced(!showAdvanced)}
                className="text-blue-600 hover:text-blue-700 flex items-center gap-2"
              >
                {showAdvanced ? <FaChevronUp /> : <FaChevronDown />}
                {showAdvanced ? 'Gizle' : 'Göster'}
              </button>
            </div>

            <div className={`space-y-4 ${showAdvanced ? '' : 'hidden'}`}>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Kilo (kg)
                  </label>
                  <input
                    type="number"
                    step="0.1"
                    name="weight"
                    value={form.weight}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Vücut Sıcaklığı (°C)
                  </label>
                  <input
                    type="number"
                    step="0.1"
                    name="body_temperature"
                    value={form.body_temperature}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Genel Durum
                  </label>
                  <select
                    name="general_condition"
                    value={form.general_condition}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="NORMAL">Normal</option>
                    <option value="ABNORMAL">Anormal</option>
                    <option value="CRITICAL">Kritik</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Solunum Hızı (/dk)
                  </label>
                  <input
                    type="number"
                    name="Solunum_rate"
                    value={form.Solunum_rate}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Nabız (/dk)
                  </label>
                  <input
                    type="number"
                    name="pulse"
                    value={form.pulse}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    KDZ (sn)
                  </label>
                  <input
                    type="number"
                    name="capillary_refill_time"
                    value={form.capillary_refill_time}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Lenf Nodülleri
                  </label>
                  <input
                    type="text"
                    name="lymph_nodes"
                    value={form.lymph_nodes}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Mukoz Membranlar
                  </label>
                  <input
                    type="text"
                    name="mucous_membranes"
                    value={form.mucous_membranes}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Tüy Yapısı
                  </label>
                  <input
                    type="text"
                    name="coat_structure"
                    value={form.coat_structure}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Dehidrasyon Seviyesi
                  </label>
                  <input
                    type="text"
                    name="dehydration_level"
                    value={form.dehydration_level}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Reçete Bilgileri */}
          <div className="bg-white rounded-lg border p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-gray-700">Reçete Bilgileri</h3>
              <button
                type="button"
                onClick={() => setShowPrescription(!showPrescription)}
                className="text-blue-600 hover:text-blue-700 flex items-center gap-2"
              >
                {showPrescription ? <FaChevronUp /> : <FaChevronDown />}
                {showPrescription ? 'Gizle' : 'Göster'}
              </button>
            </div>

            <div className={`space-y-4 ${showPrescription ? '' : 'hidden'}`}>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Reçete Türü
                  </label>
                  <input
                    type="text"
                    name="prescription_type"
                    value={form.prescription_type}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Seri No
                  </label>
                  <input
                    type="text"
                    name="serial_number"
                    value={form.serial_number}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Defter No
                  </label>
                  <input
                    type="text"
                    name="book_number"
                    value={form.book_number}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Sıra No
                  </label>
                  <input
                    type="text"
                    name="sequence_number"
                    value={form.sequence_number}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    E-Reçete No
                  </label>
                  <input
                    type="text"
                    name="e_prescription_number"
                    value={form.e_prescription_number}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Tutar
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    name="amount"
                    value={form.amount}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Reçete Notları
                  </label>
                  <textarea
                    name="prescription_notes"
                    value={form.prescription_notes}
                    onChange={handleChange}
                    rows="3"
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Önerilen Mama
                  </label>
                  <textarea
                    name="prescription_food"
                    value={form.prescription_food}
                    onChange={handleChange}
                    rows="3"
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="flex gap-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="approved"
                    checked={form.approved}
                    onChange={handleChange}
                    className="rounded text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-700">Onaylandı</span>
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="printed"
                    checked={form.printed}
                    onChange={handleChange}
                    className="rounded text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-700">Yazdırıldı</span>
                </label>
              </div>
            </div>
          </div>

          {/* Form Actions */}
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
              disabled={loading}
            >
              İptal
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors flex items-center gap-2"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="animate-spin">⌛</span>
                  Kaydediliyor...
                </>
              ) : (
                'Kaydet'
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default TreatmentModal;
