import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

function InvoiceDetails({ isOpen, onClose, invoice }) {
  if (!invoice) return null; // Eğer fatura seçilmediyse hiçbir şey render etme

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "400px",
          borderRadius: "10px",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        },
      }}
    >
      <h2 className="text-xl font-bold mb-4">Fatura Detayları</h2>
      <div className="space-y-2">
        <p>
          <strong>Fatura ID:</strong> {invoice.id}
        </p>
        <p>
          <strong>Müşteri Adı:</strong> {invoice.customerName}
        </p>
        <p>
          <strong>Tarih:</strong> {invoice.date}
        </p>
        <p>
          <strong>Tutar:</strong> {invoice.amount} ₺
        </p>
        <p>
          <strong>Durum:</strong>{" "}
          <span
            className={`px-2 py-1 rounded ${
              invoice.status === "Paid"
                ? "bg-green-100 text-green-700"
                : "bg-orange-100 text-orange-700"
            }`}
          >
            {invoice.status}
          </span>
        </p>
        <p>
          <strong>Açıklama:</strong> {invoice.description}
        </p>
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={onClose}
          className="px-4 py-2 bg-red-500 text-white rounded-lg shadow hover:bg-red-600"
        >
          Kapat
        </button>
      </div>
    </Modal>
  );
}

export default InvoiceDetails;
