import React from "react";
import { FaFileInvoiceDollar, FaClock, FaCheckCircle } from "react-icons/fa";

function InvoiceSummaryCards() {
  const summaryData = [
    {
      title: "Toplam Gelir",
      value: "₺12,450",
      icon: <FaFileInvoiceDollar className="text-blue-500 text-3xl" />,
      description: "Bu ayın toplam gelir miktarı",
      bgColor: "bg-blue-100",
    },
    {
      title: "Bekleyen Faturalar",
      value: "8",
      icon: <FaClock className="text-orange-500 text-3xl" />,
      description: "Ödeme bekleyen faturaların sayısı",
      bgColor: "bg-orange-100",
    },
    {
      title: "Tamamlanan Faturalar",
      value: "32",
      icon: <FaCheckCircle className="text-green-500 text-3xl" />,
      description: "Tamamlanan faturaların toplamı",
      bgColor: "bg-green-100",
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
      {summaryData.map((card, index) => (
        <div
          key={index}
          className={`p-4 rounded-lg shadow-md flex items-center ${card.bgColor}`}
        >
          <div className="p-2 rounded-lg mr-4 bg-white shadow">
            {card.icon}
          </div>
          <div>
            <h3 className="text-lg font-bold">{card.title}</h3>
            <p className="text-2xl font-semibold">{card.value}</p>
            <p className="text-sm text-gray-600">{card.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default InvoiceSummaryCards;
