import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Button,
  TextField,
  Modal,
  Select as MuiSelect,
  FormControl,
  InputLabel,
} from '@mui/material';
import { FaEllipsisV, FaPlus, FaSearch } from 'react-icons/fa';
import axios from 'axios';

const IlacSupplement = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [productTypeFilter, setProductTypeFilter] = useState('all');
  const [modalOpen, setModalOpen] = useState(false);
  const [newProduct, setNewProduct] = useState({
    name: '',
    product_type: '',
    main_group: '',
    purchase_price: '',
    sale_price: '',
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    filterData();
  }, [data, searchTerm, productTypeFilter]);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.dev1.fugesoft.com/api/vaccines');
      const sortedData = Array.isArray(response.data) 
        ? response.data.sort((a, b) => a.name.localeCompare(b.name))
        : [response.data];
      setData(sortedData);
      setFilteredData(sortedData);
      setLoading(false);
    } catch (err) {
      setError('Veri yüklenirken bir hata oluştu.');
      setLoading(false);
    }
  };

  const filterData = () => {
    let filtered = [...data];

    // Arama filtresi
    if (searchTerm) {
      filtered = filtered.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.product_type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.main_group?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Ürün tipi filtresi
    if (productTypeFilter !== 'all') {
      filtered = filtered.filter(item => item.product_type === productTypeFilter);
    }

    setFilteredData(filtered);
    setPage(0);
  };

  // Menü işlemleri
  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  // Sayfalama işlemleri
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddProduct = async () => {
    try {
      await axios.post('https://api.dev1.fugesoft.com/api/vaccines', newProduct);
      fetchData();
      setModalOpen(false);
      setNewProduct({
        name: '',
        product_type: '',
        main_group: '',
        purchase_price: '',
        sale_price: '',
      });
    } catch (error) {
      console.error('Ürün eklenirken hata oluştu:', error);
      alert('Ürün eklenirken bir hata oluştu!');
    }
  };

  const productTypes = ['İlaç', 'Supplement', 'Aşı', 'Vitamin'];

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Typography color="error">{error}</Typography>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="bg-white rounded-xl shadow-md p-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <Typography variant="h5" className="text-gray-800 font-semibold">
            İlaç & Supplement Listesi
          </Typography>
          <div className="flex items-center gap-4">
            <TextField
              placeholder="Ara..."
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ width: '200px' }}
              InputProps={{
                startAdornment: <FaSearch className="text-gray-400 mr-2" />,
              }}
            />
            <FormControl size="small" sx={{ width: '150px' }}>
              <InputLabel>Ürün Tipi</InputLabel>
              <MuiSelect
                value={productTypeFilter}
                onChange={(e) => setProductTypeFilter(e.target.value)}
                label="Ürün Tipi"
              >
                <MenuItem value="all">Tümü</MenuItem>
                {productTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
            <Button
              variant="contained"
              startIcon={<FaPlus />}
              onClick={() => setModalOpen(true)}
              className="bg-blue-600 hover:bg-blue-700"
            >
              Yeni Ürün Ekle
            </Button>
          </div>
        </div>

        <TableContainer component={Paper} className="shadow-sm rounded-xl overflow-hidden">
          <Table>
            <TableHead>
              <TableRow className="bg-gray-50">
                <TableCell className="font-semibold text-gray-700 border-b">
                  İsim
                </TableCell>
                <TableCell className="font-semibold text-gray-700 border-b">
                  Ürün Tipi
                </TableCell>
                <TableCell className="font-semibold text-gray-700 border-b">
                  Ana Grup
                </TableCell>
                <TableCell align="right" className="font-semibold text-gray-700 border-b">
                  Alış Fiyatı
                </TableCell>
                <TableCell align="right" className="font-semibold text-gray-700 border-b">
                  Satış Fiyatı
                </TableCell>
                <TableCell align="center" className="font-semibold text-gray-700 border-b">
                  İşlemler
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row._id} className="hover:bg-gray-50">
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.product_type}</TableCell>
                    <TableCell>{row.main_group || '-'}</TableCell>
                    <TableCell align="right">{row.purchase_price} ₺</TableCell>
                    <TableCell align="right">{row.sale_price} ₺</TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={(e) => handleMenuClick(e, row)}
                      >
                        <FaEllipsisV size={16} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Sayfa başına satır:"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
          />
        </TableContainer>
      </div>

      {/* Yeni Ürün Ekleme Modalı */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        className="flex items-center justify-center"
      >
        <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4">
          <Typography variant="h6" className="mb-4">
            Yeni Ürün Ekle
          </Typography>
          <div className="space-y-4">
            <TextField
              fullWidth
              label="Ürün Adı"
              value={newProduct.name}
              onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
            />
            <FormControl fullWidth>
              <InputLabel>Ürün Tipi</InputLabel>
              <MuiSelect
                value={newProduct.product_type}
                onChange={(e) => setNewProduct({ ...newProduct, product_type: e.target.value })}
                label="Ürün Tipi"
              >
                {productTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
            <TextField
              fullWidth
              label="Ana Grup"
              value={newProduct.main_group}
              onChange={(e) => setNewProduct({ ...newProduct, main_group: e.target.value })}
            />
            <TextField
              fullWidth
              label="Alış Fiyatı"
              type="number"
              value={newProduct.purchase_price}
              onChange={(e) => setNewProduct({ ...newProduct, purchase_price: e.target.value })}
            />
            <TextField
              fullWidth
              label="Satış Fiyatı"
              type="number"
              value={newProduct.sale_price}
              onChange={(e) => setNewProduct({ ...newProduct, sale_price: e.target.value })}
            />
          </div>
          <div className="flex justify-end gap-2 mt-6">
            <Button onClick={() => setModalOpen(false)} color="inherit">
              İptal
            </Button>
            <Button
              onClick={handleAddProduct}
              variant="contained"
              className="bg-blue-600 hover:bg-blue-700"
            >
              Ekle
            </Button>
          </div>
        </div>
      </Modal>

      {/* İşlem Menüsü */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.12))',
            mt: 1,
            borderRadius: 2,
            minWidth: 150,
          },
        }}
      >
        <MenuItem onClick={handleMenuClose}>Düzenle</MenuItem>
        <MenuItem onClick={handleMenuClose}>Detaylar</MenuItem>
        <MenuItem onClick={handleMenuClose} className="text-red-600">
          Sil
        </MenuItem>
      </Menu>
    </div>
  );
};

export default IlacSupplement;
