import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Modal from "react-modal";
import axios from "axios";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CalendarScreen.css";
import AppointmentModal from "../../../components/AppointmentModal";
import Select from "react-select";

const localizer = momentLocalizer(moment);

// Configure moment to use local timezone
moment.locale('tr');

Modal.setAppElement("#root");

// Pastel renk stilleri
const styles = {
  blue: {
    backgroundColor: 'rgba(59, 130, 246, 0.15)',
    color: '#1e40af',
    borderLeft: '3px solid #3B82F6',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
  },
  green: {
    backgroundColor: 'rgba(16, 185, 129, 0.15)',
    color: '#065f46',
    borderLeft: '3px solid #10B981',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
  },
  red: {
    backgroundColor: 'rgba(239, 68, 68, 0.15)',
    color: '#991b1b',
    borderLeft: '3px solid #EF4444',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
  },
  purple: {
    backgroundColor: 'rgba(139, 92, 246, 0.15)',
    color: '#5b21b6',
    borderLeft: '3px solid #8B5CF6',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
  },
  orange: {
    backgroundColor: 'rgba(249, 115, 22, 0.15)',
    color: '#9a3412',
    borderLeft: '3px solid #F97316',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
  }
};

function CalendarScreen() {
  const [events, setEvents] = useState([]);
  const [pets, setPets] = useState([]);
  const [users, setUsers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [newEvent, setNewEvent] = useState({
    petId: "",
    userId: "",
    assignedTo: "",
    owner_name: "",
    owner_address: "",
    owner_phone: "",
    owner_email: "",
    pet_name: "",
    pet_species: "",
    pet_breed: "",
    pet_age: 0,
    description: "",
    appointment_type: "",
    appointment_package: "",
    department: "Klinik",
    veterinarian: "",
    notes: "",
    status: "Yapılacak",
    protocol_number: "",
    card_number: "",
    date: "",
    startTime: "",
    endTime: "",
    time: "",
    color: "blue"
  });
  const token = localStorage.getItem("authToken");
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [currentView, setCurrentView] = useState('month');
  const [currentDate, setCurrentDate] = useState(moment());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let filtered = [...events];

    if (selectedColor && selectedColor.value !== 'all') {
      filtered = filtered.filter(event => event.color === selectedColor.value);
    }

    if (selectedUser) {
      filtered = filtered.filter(event => event.assignedTo === selectedUser.label.split(' - ')[0]);
    }

    setFilteredEvents(filtered);
  }, [events, selectedColor, selectedUser]);

  const fetchAppointments = async (date = currentDate, view = currentView) => {
    try {
      setLoading(true);
      console.log(`Fetching appointments for ${view} view...`);
      
      // Calculate date range based on view type
      let startDate, endDate;
      
      switch(view) {
        case 'month':
          startDate = date.clone().startOf('month').format('YYYY-MM-DD');
          endDate = date.clone().endOf('month').format('YYYY-MM-DD');
          break;
        case 'week':
          startDate = date.clone().startOf('week').format('YYYY-MM-DD');
          endDate = date.clone().endOf('week').format('YYYY-MM-DD');
          break;
        case 'day':
          // Günlük görünüm için bir gün öncesi ve sonrasını da al
          startDate = date.clone().subtract(1, 'day').format('YYYY-MM-DD');
          endDate = date.clone().add(1, 'day').format('YYYY-MM-DD');
          break;
        default:
          startDate = date.clone().startOf('month').format('YYYY-MM-DD');
          endDate = date.clone().endOf('month').format('YYYY-MM-DD');
      }

      console.log(`View: ${view}, Fetching range: ${startDate} to ${endDate}`);

      // API çağrısı query string ile
      const response = await axios.get(
        `https://api.dev1.fugesoft.com/api/appointments?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: { 
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache'
          }
        }
      );

      const { appointments } = response.data;
      console.log(`Received ${appointments?.length || 0} appointments from API`);

      if (!appointments || !Array.isArray(appointments)) {
        console.warn("No appointments data received or invalid format");
        setEvents([]);
        setLoading(false);
        return;
      }

      const formattedEvents = appointments
        .map((item) => {
          const dateStr = item.date;
          const timeStr = item.time;
          
          if (!dateStr || !timeStr) {
            console.warn("Invalid date/time for appointment:", item);
            return null;
          }

          let [startTime] = timeStr.split(' - ');
          if (!startTime) {
            console.warn("Invalid time format:", timeStr);
            return null;
          }

          const start = moment(`${dateStr} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
          if (!start.isValid()) {
            console.warn("Invalid moment date for:", { dateStr, timeStr, item });
            return null;
          }

          const end = moment(start).add(1, 'hour');

          // Günlük görünümde sadece seçili güne ait randevuları göster
          if (view === 'day') {
            const appointmentDate = moment(dateStr).format('YYYY-MM-DD');
            const selectedDate = date.format('YYYY-MM-DD');
            if (appointmentDate !== selectedDate) {
              return null;
            }
          }

          return {
            id: item._id,
            title: `${item.owner_name} (${item.pet_name || 'İsimsiz'})`,
            description: item.appointment_package || "Detay yok",
            start: start.toDate(),
            end: end.toDate(),
            color: item.color || "blue",
            assignedTo: item.assignedTo || "",
            status: item.status || "Yapılacak",
            owner_name: item.owner_name,
            pet_name: item.pet_name,
            appointment_package: item.appointment_package,
          };
        })
        .filter(Boolean); // null değerleri filtrele

      console.log(`Formatted ${formattedEvents.length} events for ${view} view`);
      
      setEvents(formattedEvents);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching appointments:", error);
      setLoading(false);
    }
  };

  const fetchPets = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/pets", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const petOptions = response.data.map((pet) => ({
        value: pet._id,
        label: `${pet.name} - ${pet.owner_name}`,
        pet_species: pet.species || "Bilinmiyor",
        pet_age: pet.age || 0,
      }));
      setPets(petOptions);
    } catch (error) {
      alert("Hayvanlar yüklenirken bir hata oluştu!");
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userOptions = response.data.map((user) => {
        const name = user.name || '';
        const surname = user.surname || '';
        const role = user.role || '';
        
        return {
          value: user._id || user.id,
          label: `${name} ${surname} - ${role}`.trim(),
          color: user.color || 'blue'
        };
      }).filter(user => user.label && user.label !== ' - ');
      
      setUsers(userOptions);
    } catch (error) {
      alert("Kullanıcılar yüklenirken bir hata oluştu!");
    }
  };

  const handleAddEvent = async () => {
    try {
      if (!newEvent.assignedTo) {
        alert("Lütfen bir çalışan seçiniz!");
        return;
      }

      if (!newEvent.date || !newEvent.startTime) {
        alert("Lütfen tarih ve saat seçiniz!");
        return;
      }

      const selectedUser = users.find(user => user.value === newEvent.assignedTo);
      if (!selectedUser) {
        alert("Seçilen çalışan bulunamadı!");
        return;
      }

      const userFullName = selectedUser.label.split(' - ')[0];

      // Format the time properly with seconds
      const timeWithSeconds = moment(newEvent.startTime, 'HH:mm').format('HH:mm:00');
      const selectedDate = moment(newEvent.date).format('YYYY-MM-DD');
      
      const appointmentData = {
        petId: newEvent.petId,
        userId: localStorage.getItem("userId"),
        assignedTo: userFullName,
        owner_name: newEvent.owner_name,
        owner_address: newEvent.owner_address || "",
        owner_phone: newEvent.owner_phone || "",
        owner_email: newEvent.owner_email || "",
        pet_name: newEvent.pet_name || "Not Specified",
        pet_species: newEvent.pet_species || "Not Specified",
        pet_breed: newEvent.pet_breed || "",
        pet_age: newEvent.pet_age || 0,
        description: newEvent.description || "",
        appointment_type: newEvent.appointment_type || "Aşılama",
        appointment_package: newEvent.appointment_package || "",
        department: newEvent.department || "Klinik",
        veterinarian: userFullName,
        notes: newEvent.notes || "",
        status: "Yapılacak",
        protocol_number: newEvent.protocol_number || "",
        card_number: newEvent.card_number || "",
        date: selectedDate,
        time: timeWithSeconds,
        color: selectedUser.color || "blue"
      };

      console.log("Sending appointment data:", appointmentData);
      console.log("Date and time being sent:", {
        date: appointmentData.date,
        time: appointmentData.time,
        originalDate: newEvent.date,
        originalTime: newEvent.startTime
      });

      setLoading(true);
      const response = await axios.post(
        "https://api.dev1.fugesoft.com/api/appointments",
        appointmentData,
        {
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log("Appointment creation response:", response.data);
      
      // Fetch appointments for the current view immediately after creating
      await fetchAppointments(moment(selectedDate), currentView);
      closeModal();

      // Reset form
      setNewEvent({
        petId: "",
        userId: "",
        assignedTo: "",
        owner_name: "",
        owner_address: "",
        owner_phone: "",
        owner_email: "",
        pet_name: "",
        pet_species: "",
        pet_breed: "",
        pet_age: 0,
        description: "",
        appointment_type: "",
        appointment_package: "",
        department: "Klinik",
        veterinarian: "",
        notes: "",
        status: "Yapılacak",
        protocol_number: "",
        card_number: "",
        date: "",
        startTime: "",
        color: "blue"
      });
    } catch (error) {
      console.error("Error creating appointment:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
      }
      alert("Randevu oluşturulurken bir hata oluştu!");
    } finally {
      setLoading(false);
    }
  };

  const openModal = (slotInfo) => {
    const date = moment(slotInfo.start).format("YYYY-MM-DD");
    const startTime = moment(slotInfo.start).format("HH:mm");
    const endTime = moment(slotInfo.end).format("HH:mm");
    setNewEvent({
      ...newEvent,
      date,
      startTime,
      endTime,
      time: startTime, // Keep this for API compatibility
    });
    setModalIsOpen(true);
  };

  const closeModal = () => setModalIsOpen(false);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setDetailsModalIsOpen(true);
  };

  const closeDetailsModal = () => setDetailsModalIsOpen(false);

  const colorOptions = [
    { value: 'all', label: 'Hepsi', color: '#6B7280' },
    { value: 'blue', label: 'Mavi', color: '#3B82F6' },
    { value: 'green', label: 'Yeşil', color: '#10B981' },
    { value: 'red', label: 'Kırmızı', color: '#EF4444' },
    { value: 'purple', label: 'Mor', color: '#8B5CF6' },
    { value: 'orange', label: 'Turuncu', color: '#F97316' }
  ];

  const handleClearFilters = () => {
    setSelectedColor(null);
    setSelectedUser(null);
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
    fetchAppointments(currentDate, view);
  };

  const handleNavigate = (newDate) => {
    const momentDate = moment(newDate);
    setCurrentDate(momentDate);
    fetchAppointments(momentDate, currentView);
  };

  // Event stil yönetimi için yeni fonksiyon
  const eventPropGetter = (event) => {
    const style = styles[event.color] || styles.blue;
    return { style };
  };

  // Initial fetch
  useEffect(() => {
    fetchAppointments();
    fetchPets();
    fetchUsers();
  }, []);

  return (
    <div className="p-4">
      <div className="calendar-container p-4">
        {loading && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        )}
        <div className="flex justify-between items-center mb-4">
  {/* Header Section */}
  <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-800">Randevu Takvimi</h2>
        <p className="text-gray-600 mt-1">Randevularnızı kolayca takip edin!</p>
      </div>          
          {/* Filtreler */}
          <div className="flex items-center gap-4 bg-white p-3 rounded-lg shadow-sm">
            <div className="w-48">
              <Select
                value={selectedColor}
                onChange={setSelectedColor}
                options={colorOptions}
                isClearable
                placeholder="Renge göre filtrele..."
                formatOptionLabel={option => (
                  <div className="flex items-center">
                    <div
                      className="w-4 h-4 rounded-full mr-2"
                      style={{ backgroundColor: option.color }}
                    />
                    {option.label}
                  </div>
                )}
              />
            </div>
            <div className="w-48">
              <Select
                value={selectedUser}
                onChange={setSelectedUser}
                options={users}
                isClearable
                placeholder="Çalışana göre filtrele..."
              />
            </div>
            {(selectedColor || selectedUser) && (
              <button
                onClick={handleClearFilters}
                className="px-3 py-2 text-sm text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded"
              >
                Filtreleri Temizle
              </button>
            )}
          </div>
        </div>

        <Calendar
          localizer={localizer}
          events={filteredEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 'calc(100vh - 200px)' }}
          onSelectEvent={event => {
            console.log("Selected calendar event:", event);
            setSelectedEvent(event);
            setDetailsModalIsOpen(true);
          }}
          onSelectSlot={slotInfo => {
            console.log("Selected slot:", slotInfo);
            openModal(slotInfo);
          }}
          selectable
          eventPropGetter={eventPropGetter}
          defaultView={currentView}
          views={["month", "week", "day"]}
          toolbar={true}
          popup={true}
          step={30}
          timeslots={2}
          min={new Date(0, 0, 0, 7, 0, 0)} // Gün başlangıcı 07:00
          max={new Date(0, 0, 0, 21, 0, 0)} // Gün bitişi 21:00
          scrollToTime={new Date(0, 0, 0, 7, 0, 0)}
          formats={{
            timeGutterFormat: 'HH:mm',
            eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
              `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
          }}
          onView={handleViewChange}
          onNavigate={handleNavigate}
          messages={{
            next: "İleri",
            previous: "Geri",
            today: "Bugün",
            month: "Ay",
            week: "Hafta",
            day: "Gün",
            noEventsInRange: "Bu aralıkta randevu bulunmamaktadır."
          }}
        />
        <AppointmentModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          pets={pets}
          users={users}
          newEvent={newEvent}
          setNewEvent={setNewEvent}
          handleAddEvent={handleAddEvent}
        />
        {/* Detay Modalı */}
        {selectedEvent && (
          <Modal
            isOpen={detailsModalIsOpen}
            onRequestClose={closeDetailsModal}
            className="modal-content"
            overlayClassName="modal-overlay"
          >
            <h2 className="text-xl font-bold mb-4">{selectedEvent.title}</h2>
            <p><strong>Açıklama:</strong> {selectedEvent.description}</p>
            <p><strong>Başlangıç:</strong> {moment(selectedEvent.start).format("YYYY-MM-DD HH:mm")}</p>
            <p><strong>Bitiş:</strong> {moment(selectedEvent.end).format("YYYY-MM-DD HH:mm")}</p>
            <button
              onClick={closeDetailsModal}
              className="mt-4 px-4 py-2 bg-gray-500 text-white rounded"
            >
              Kapat
            </button>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default CalendarScreen;
