import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { 
  FaPlus, FaEdit, FaTrash, FaPaw, FaCalendar, FaUser, FaPhone, 
  FaMapMarkerAlt, FaIdCard, FaMoneyBill, FaCat, FaDog, FaDove, 
  FaHorse, FaHistory, FaBell, FaEnvelope, FaSms, FaFileAlt,
  FaChartLine, FaClipboardList, FaExclamationCircle
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'react-modal';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import LoadingSpinner from '../../../components/LoadingSpinner';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '0.5rem',
    maxWidth: '500px',
    width: '90%',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

function PetOwnerDetailsScreen() {
  const { owner_id } = useParams();
  const navigate = useNavigate();
  const [ownerDetails, setOwnerDetails] = useState(null);
  const [pets, setPets] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [formData, setFormData] = useState({});
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [newNote, setNewNote] = useState('');
  const itemsPerPage = 5;

  // Mock data
  const mockPaymentHistory = [
    { id: 1, date: '2024-01-20', amount: 450, description: 'Rutin kontrol' },
    { id: 2, date: '2024-01-15', amount: 850, description: 'Aşı uygulaması' },
    { id: 3, date: '2024-01-10', amount: 1200, description: 'Tedavi' },
  ];

  const mockNotifications = [
    { id: 1, type: 'vaccine', pet: 'Max', dueDate: '2024-02-01', message: 'Kuduz aşısı zamanı yaklaşıyor' },
    { id: 2, type: 'appointment', pet: 'Bella', dueDate: '2024-02-05', message: 'Kontrol randevusu' },
    { id: 3, type: 'vaccine', pet: 'Luna', dueDate: '2024-02-10', message: 'Karma aşı zamanı yaklaşıyor' },
  ];

  const mockNotes = [
    { id: 1, date: '2024-01-20', note: 'Hasta sahibi düzenli kontrollere geliyor', author: 'Dr. Ahmet' },
    { id: 2, date: '2024-01-15', note: 'Ödemelerde gecikme yaşanıyor', author: 'Ayşe Hemşire' },
  ];

  const mockStats = {
    totalVisits: 15,
    totalSpent: 5600,
    lastVisit: '2024-01-20',
    averageVisitPerMonth: 3,
  };

  const token = localStorage.getItem('authToken');
  const baseURL = 'https://api.dev1.fugesoft.com/api';

  // API calls
  const fetchOwnerDetails = async () => {
    try {
      const response = await axios.get(`${baseURL}/owners/${owner_id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setOwnerDetails(response.data);
      // Set pets from owner details
      setPets(response.data.pets || []);
    } catch (error) {
      toast.error('Hasta bilgileri yüklenirken hata oluştu');
      console.error('Error fetching owner details:', error);
      setPets([]); // Set empty array on error
    }
  };

  const fetchAppointments = async () => {
    try {
      const response = await axios.get(`${baseURL}/owners/${owner_id}/appointments`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAppointments(response.data || []); // Ensure we always have an array
    } catch (error) {
      toast.error('Randevu bilgileri yüklenirken hata oluştu');
      console.error('Error fetching appointments:', error);
      setAppointments([]); // Set empty array on error
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([
        fetchOwnerDetails(),
        fetchAppointments()
      ]);
      setIsLoading(false);
    };
    fetchData();
  }, [owner_id]);

  // Modal handlers
  const openModal = (type) => {
    setModalType(type);
    setFormData({});
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFormData({});
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (modalType === 'pet') {
        const petData = {
          ...formData,
          owner_id: owner_id // Add owner_id to the pet data
        };
        await axios.post(`${baseURL}/pets`, petData, {
          headers: { Authorization: `Bearer ${token}` }
        });
        toast.success('Evcil hayvan başarıyla eklendi');
        await fetchOwnerDetails(); // Refresh owner details to get updated pets list
      } else {
        await axios.post(`${baseURL}/owners/${owner_id}/appointments`, formData, {
          headers: { Authorization: `Bearer ${token}` }
        });
        toast.success('Randevu başarıyla eklendi');
        await fetchAppointments();
      }
      closeModal();
    } catch (error) {
      toast.error(`${modalType === 'pet' ? 'Evcil hayvan' : 'Randevu'} eklenirken hata oluştu`);
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (type, id) => {
    const confirmed = window.confirm(`Bu ${type === 'pet' ? 'evcil hayvanı' : 'randevuyu'} silmek istediğinizden emin misiniz?`);
    if (!confirmed) return;

    setIsLoading(true);
    try {
      await axios.delete(`${baseURL}/${type}s/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success(`${type === 'pet' ? 'Evcil hayvan' : 'Randevu'} başarıyla silindi`);
      if (type === 'pet') {
        await fetchOwnerDetails(); // Refresh owner details to get updated pets list
      } else {
        await fetchAppointments();
      }
    } catch (error) {
      toast.error(`${type === 'pet' ? 'Evcil hayvan' : 'Randevu'} silinirken hata oluştu`);
      console.error('Error deleting item:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPetIcon = (species) => {
    const speciesLower = (species || '').toLowerCase();
    if (speciesLower.includes('kedi')) {
      return { icon: FaCat, color: 'bg-orange-100 text-orange-500' };
    } else if (speciesLower.includes('köpek')) {
      return { icon: FaDog, color: 'bg-blue-100 text-blue-500' };
    } else if (speciesLower.includes('kuş')) {
      return { icon: FaDove, color: 'bg-sky-100 text-sky-500' };
    } else if (speciesLower.includes('at')) {
      return { icon: FaHorse, color: 'bg-amber-100 text-amber-500' };
    }
    return { icon: FaPaw, color: 'bg-purple-100 text-purple-500' };
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!ownerDetails) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h2 className="text-2xl font-bold mb-4">Müşteri bulunamadı</h2>
        <button
          onClick={() => navigate(-1)}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Geri Dön
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="mb-6 flex justify-between items-center">
        <h2 className="text-3xl font-bold flex items-center">
          <FaUser className="mr-2" />
          Müşteri Detayları
        </h2>
        <button
          onClick={() => navigate(-1)}
          className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
        >
          Geri Dön
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Owner Information Card */}
        <div className="space-y-6">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4 flex items-center">
              <FaUser className="mr-2" />
              Müşteri Bilgileri
            </h3>
            <div className="space-y-3">
              <p className="flex items-center text-gray-700">
                <FaUser className="mr-2" />
                <span className="font-semibold">İsim:</span> {ownerDetails.name}
              </p>
              <p className="flex items-center text-gray-700">
                <FaPhone className="mr-2" />
                <span className="font-semibold">Telefon:</span> {ownerDetails.phone_number}
              </p>
              <p className="flex items-center text-gray-700">
                <FaMapMarkerAlt className="mr-2" />
                <span className="font-semibold">Adres:</span> {ownerDetails.address}
              </p>
              <p className="flex items-center text-gray-700">
                <FaIdCard className="mr-2" />
                <span className="font-semibold">TC:</span> {ownerDetails.tc}
              </p>
              <p className="flex items-center text-gray-700">
                <FaMoneyBill className="mr-2" />
                <span className="font-semibold">Toplam Ödeme:</span> {ownerDetails.totalPaid}
              </p>
            </div>
            <div className="bg-red-100 p-4 rounded-lg shadow mt-6">
              <h3 className="text-xl font-bold text-red-700 mb-2 flex items-center">
                <FaMoneyBill className="mr-2" />
                Toplam Borç
              </h3>
              <p className="text-red-600 text-2xl font-semibold">{ownerDetails.totalDebt}</p>
            </div>
          </div>

          {/* Payment History Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold flex items-center">
                <FaHistory className="mr-2" />
                Ödeme Geçmişi
              </h3>
              <button
                onClick={() => setShowPaymentHistory(!showPaymentHistory)}
                className="text-blue-500 hover:text-blue-600"
              >
                {showPaymentHistory ? 'Gizle' : 'Göster'}
              </button>
            </div>
            {showPaymentHistory && (
              <div className="space-y-3">
                {mockPaymentHistory.map(payment => (
                  <div key={payment.id} className="flex justify-between items-center p-3 bg-gray-50 rounded-lg">
                    <div>
                      <p className="font-semibold">{payment.description}</p>
                      <p className="text-sm text-gray-500">{payment.date}</p>
                    </div>
                    <span className="font-bold text-green-600">{payment.amount} TL</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Statistics Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold flex items-center mb-4">
              <FaChartLine className="mr-2" />
              İstatistikler
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-blue-50 p-4 rounded-lg">
                <p className="text-sm text-gray-600">Toplam Ziyaret</p>
                <p className="text-xl font-bold text-blue-600">{mockStats.totalVisits}</p>
              </div>
              <div className="bg-green-50 p-4 rounded-lg">
                <p className="text-sm text-gray-600">Toplam Harcama</p>
                <p className="text-xl font-bold text-green-600">{mockStats.totalSpent} TL</p>
              </div>
              <div className="bg-purple-50 p-4 rounded-lg">
                <p className="text-sm text-gray-600">Aylık Ort. Ziyaret</p>
                <p className="text-xl font-bold text-purple-600">{mockStats.averageVisitPerMonth}</p>
              </div>
              <div className="bg-orange-50 p-4 rounded-lg">
                <p className="text-sm text-gray-600">Son Ziyaret</p>
                <p className="text-xl font-bold text-orange-600">{mockStats.lastVisit}</p>
              </div>
            </div>
          </div>
          
          {/* Notes Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold flex items-center mb-4">
              <FaClipboardList className="mr-2" />
              Notlar ve Ek Belgeler
            </h3>
            <div className="mb-4">
              <textarea
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                placeholder="Yeni not ekle..."
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                rows="3"
              />
              <button
                onClick={() => {
                  toast.success('Not eklendi');
                  setNewNote('');
                }}
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              >
                Not Ekle
              </button>
            </div>
            <div className="space-y-3">
              {mockNotes.map(note => (
                <div key={note.id} className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex justify-between items-start mb-2">
                    <span className="font-semibold">{note.author}</span>
                    <span className="text-sm text-gray-500">{note.date}</span>
                  </div>
                  <p className="text-gray-700">{note.note}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="lg:col-span-2 space-y-6">
          {/* Notifications Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold flex items-center">
                <FaBell className="mr-2" />
                Bildirimler ve Hatırlatıcılar
              </h3>
              <div className="flex space-x-2">
                <button
                  onClick={() => toast.success('SMS gönderildi')}
                  className="bg-green-500 text-white px-3 py-2 rounded-lg hover:bg-green-600 flex items-center"
                >
                  <FaSms className="mr-2" />
                  SMS Gönder
                </button>
                <button
                  onClick={() => toast.success('E-posta gönderildi')}
                  className="bg-blue-500 text-white px-3 py-2 rounded-lg hover:bg-blue-600 flex items-center"
                >
                  <FaEnvelope className="mr-2" />
                  E-posta Gönder
                </button>
              </div>
            </div>
            <div className="space-y-3">
              {mockNotifications.map(notification => (
                <div key={notification.id} className="flex items-start p-4 bg-yellow-50 rounded-lg">
                  <FaExclamationCircle className="text-yellow-500 mt-1 mr-3" />
                  <div>
                    <p className="font-semibold">{notification.pet} - {notification.message}</p>
                    <p className="text-sm text-gray-600">Tarih: {notification.dueDate}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Pets Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold flex items-center">
                <FaPaw className="mr-2" />
                Evcil Hayvanlar
              </h3>
              <button
                onClick={() => openModal('pet')}
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 flex items-center"
              >
                <FaPlus className="mr-2" /> Yeni Evcil Hayvan
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {pets.map((pet) => {
                const { icon: PetIcon, color } = getPetIcon(pet.species);
                return (
                  <div 
                    key={pet.id} 
                    className="bg-gray-50 p-4 rounded-lg shadow transition-all hover:shadow-md cursor-pointer hover:bg-gray-100"
                    onClick={() => navigate(`/pet-details/${pet._id}`)}
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <h4 className="text-lg font-bold mb-2 flex items-center">
                          <PetIcon className={`mr-2 ${color}`} />
                          {pet.name}
                        </h4>
                        <p className="text-gray-600"><span className="font-semibold">Tür:</span> {pet.species || 'Belirtilmemiş'}</p>
                        <p className="text-gray-600"><span className="font-semibold">Cins:</span> {pet.breed || 'Belirtilmemiş'}</p>
                        <p className="text-gray-600"><span className="font-semibold">Yaş:</span> {pet.age || 'Belirtilmemiş'}</p>
                      </div>
                      <div className="flex space-x-2" onClick={(e) => e.stopPropagation()}>
                        <button
                          onClick={() => handleDelete('pet', pet.id)}
                          className="text-red-500 hover:text-red-600"
                          title="Sil"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
              {pets.length === 0 && (
                <div className="col-span-2 text-center py-8 text-gray-500">
                  Henüz evcil hayvan eklenmemiş
                </div>
              )}
            </div>
          </div>

          {/* Appointments Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold flex items-center">
                <FaCalendar className="mr-2" />
                Randevular
              </h3>
              <button
                onClick={() => openModal('appointment')}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center"
              >
                <FaPlus className="mr-2" /> Yeni Randevu
              </button>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tarih</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Detaylar</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">İşlemler</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {appointments
                    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                    .map((appointment) => (
                      <tr key={appointment.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          {format(new Date(appointment.date), 'dd MMMM yyyy', { locale: tr })}
                        </td>
                        <td className="px-6 py-4">{appointment.details}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex space-x-2">
                            <button
                              onClick={() => handleDelete('appointment', appointment.id)}
                              className="text-red-500 hover:text-red-600"
                            >
                              <FaTrash />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            
            {/* Pagination */}
            <div className="flex justify-between items-center mt-4">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-4 py-2 bg-gray-100 rounded-lg disabled:bg-gray-200 disabled:cursor-not-allowed hover:bg-gray-200 transition-colors"
              >
                Önceki
              </button>
              <span className="text-sm text-gray-600">
                Sayfa {currentPage} / {Math.max(1, Math.ceil(appointments.length / itemsPerPage))}
              </span>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(appointments.length / itemsPerPage)))}
                disabled={currentPage >= Math.ceil(appointments.length / itemsPerPage)}
                className="px-4 py-2 bg-gray-100 rounded-lg disabled:bg-gray-200 disabled:cursor-not-allowed hover:bg-gray-200 transition-colors"
              >
                Sonraki
              </button>
            </div>
          </div>

          
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel={modalType === 'pet' ? 'Yeni Evcil Hayvan' : 'Yeni Randevu'}
      >
        <div className="relative">
          <button
            onClick={closeModal}
            className="absolute top-0 right-0 text-gray-600 hover:text-gray-800"
          >
            ✕
          </button>
          <h2 className="text-2xl font-bold mb-4">
            {modalType === 'pet' ? 'Yeni Evcil Hayvan Ekle' : 'Yeni Randevu Ekle'}
          </h2>
          <form onSubmit={handleFormSubmit} className="space-y-4">
            {modalType === 'pet' ? (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">İsim</label>
                  <input
                    type="text"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.name || ''}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Tür</label>
                  <input
                    type="text"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.species || ''}
                    onChange={(e) => setFormData({ ...formData, species: e.target.value })}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Cins</label>
                  <input
                    type="text"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.breed || ''}
                    onChange={(e) => setFormData({ ...formData, breed: e.target.value })}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Yaş</label>
                  <input
                    type="number"
                    required
                    min="0"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.age || ''}
                    onChange={(e) => setFormData({ ...formData, age: parseInt(e.target.value) })}
                  />
                </div>
              </>
            ) : (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Tarih</label>
                  <input
                    type="datetime-local"
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.date || ''}
                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Detaylar</label>
                  <textarea
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    rows="3"
                    value={formData.details || ''}
                    onChange={(e) => setFormData({ ...formData, details: e.target.value })}
                  />
                </div>
              </>
            )}
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={closeModal}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
              >
                İptal
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                Kaydet
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default PetOwnerDetailsScreen;
