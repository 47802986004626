import React, { useState } from "react";

function NewInvoiceModal({ onClose }) {
  const [formData, setFormData] = useState({
    customer: "",
    items: [{ product: "", quantity: 1, price: 0 }],
    total: 0,
  });

  const handleAddItem = () => {
    setFormData((prev) => ({
      ...prev,
      items: [...prev.items, { product: "", quantity: 1, price: 0 }],
    }));
  };

  const handleItemChange = (index, key, value) => {
    const updatedItems = [...formData.items];
    updatedItems[index][key] = value;
    setFormData((prev) => ({ ...prev, items: updatedItems }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Yeni Fatura Ekle</h2>
        <div className="mb-4">
          <label className="block mb-2">Müşteri</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            placeholder="Müşteri Adı"
            value={formData.customer}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, customer: e.target.value }))
            }
          />
        </div>
        <div className="mb-4">
          <h3 className="font-semibold mb-2">Ürünler</h3>
          {formData.items.map((item, index) => (
            <div key={index} className="flex space-x-2 mb-2">
              <input
                type="text"
                placeholder="Ürün"
                className="w-1/2 p-2 border rounded"
                value={item.product}
                onChange={(e) =>
                  handleItemChange(index, "product", e.target.value)
                }
              />
              <input
                type="number"
                placeholder="Miktar"
                className="w-1/4 p-2 border rounded"
                value={item.quantity}
                onChange={(e) =>
                  handleItemChange(index, "quantity", parseInt(e.target.value))
                }
              />
              <input
                type="number"
                placeholder="Fiyat"
                className="w-1/4 p-2 border rounded"
                value={item.price}
                onChange={(e) =>
                  handleItemChange(index, "price", parseFloat(e.target.value))
                }
              />
            </div>
          ))}
          <button
            className="bg-blue-500 text-white py-1 px-4 rounded mt-2"
            onClick={handleAddItem}
          >
            Ürün Ekle
          </button>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white py-2 px-4 rounded"
          >
            İptal
          </button>
          <button className="bg-green-500 text-white py-2 px-4 rounded">
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewInvoiceModal;
