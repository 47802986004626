import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../../Navbar";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { motion, AnimatePresence } from "framer-motion";
import { FiPlus, FiX, FiSearch, FiFilter, FiClock, FiTrash2 } from "react-icons/fi";

const categories = ["all", "lab", "istemler", "görüşmeler", "operasyon", "ilaç"];
const statuses = ["To Do", "In Progress", "Done"];
const assignees = ["all", "Clinic Worker", "Assistant", "Intern", "Doctor", "Nurse"];

const categoryColors = {
  lab: { bg: "bg-red-100", border: "border-red-500", text: "text-red-700" },
  istemler: { bg: "bg-blue-100", border: "border-blue-500", text: "text-blue-700" },
  görüşmeler: { bg: "bg-green-100", border: "border-green-500", text: "text-green-700" },
  operasyon: { bg: "bg-purple-100", border: "border-purple-500", text: "text-purple-700" },
  ilaç: { bg: "bg-pink-100", border: "border-pink-500", text: "text-pink-700" },
};

// Kolon yapılandırmasını basitleştirelim
const COLUMNS = [
  { id: 'todo', title: 'Yapılacak', status: 'To Do' },
  { id: 'inProgress', title: 'Yapılıyor', status: 'In Progress' },
  { id: 'done', title: 'Tamamlandı', status: 'Done' }
];

function KanbanBoard({ showNavbar = true, petId = null }) {
  // State'i basitleştirelim
  const [tasks, setTasks] = useState({
    todo: [],
    inProgress: [],
    done: []
  });
  const [filter, setFilter] = useState("all");
  const [assigneeFilter, setAssigneeFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTask, setSelectedTask] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [draggedTask, setDraggedTask] = useState(null);
  const [pets, setPets] = useState([]);

  const [newTask, setNewTask] = useState({
    title: "",
    description: "",
    assignedTo: "",
    createdBy: "",
    assignedBy: "",
    category: "lab",
    status: "To Do",
    todoPet: "", 
  });
  
  const [taskWasDragged, setTaskWasDragged] = useState(false);
  const [showHistory, setShowHistory] = useState(false); // History toggle state
  const [historyTasks, setHistoryTasks] = useState([]); // History tasks state
  const location = useLocation();

  const fetchTasks = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const endpoint = petId
        ? `https://api.dev1.fugesoft.com/api/tasks/petid/${petId}`
        : "https://api.dev1.fugesoft.com/api/tasks";
  
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const fetchedTasks = {
        todo: [],
        inProgress: [],
        done: []
      };

      const historyTasks = [];
      const tasks = Array.isArray(response.data)
        ? response.data
        : response.data.tasks || [];
  
      const eightHoursAgo = new Date();
      eightHoursAgo.setHours(eightHoursAgo.getHours() - 8);
  
      tasks.forEach((task) => {
        if (task.status === "To Do") {
          fetchedTasks.todo.push(task);
        } else if (task.status === "In Progress") {
          fetchedTasks.inProgress.push(task);
        } else if (task.status === "Done") {
          if (new Date(task.updated_at) < eightHoursAgo) {
            historyTasks.push(task);
          } else {
            fetchedTasks.done.push(task);
          }
        }
      });
  
      setTasks(fetchedTasks);
      setHistoryTasks(historyTasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };
  
  
  
  
  
  
  
  
  
  
  
  

  useEffect(() => {
    fetchTasks();
  }, [petId]);

  // Fetch pets for dropdown
  const fetchPets = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get("https://api.dev1.fugesoft.com/api/pets", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const petOptions = response.data.map((pet) => ({
        value: pet._id,
        label: `${pet.name} - ${pet.owner_name}`,
      }));
      setPets(petOptions);
  
      if (petId) {
        const selectedPet = petOptions.find((pet) => pet.value === petId);
        if (selectedPet) {
          setNewTask((prev) => ({
            ...prev,
            petId: selectedPet.value,
            todoPet: selectedPet.label,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching pets:", error);
    }
  };
  
  
  

  useEffect(() => {
    fetchTasks();
    fetchPets();
  }, [petId]);


  const handleCreateTask = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post(
        "https://api.dev1.fugesoft.com/api/tasks",
        newTask,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTasks((prevTasks) => ({
        ...prevTasks,
        todo: [...prevTasks.todo, response.data],
      }));
      setShowCreateModal(false);
      setNewTask({
        title: "",
        description: "",
        assignedTo: "",
        createdBy: "",
        assignedBy: "",
        category: "lab",
        status: "To Do",
        petId: "",
        todoPet: "",
      });
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };
  

  const handlePetSelection = (selectedOption) => {
    setNewTask((prev) => ({
      ...prev,
      petId: selectedOption.value,
      todoPet: selectedOption.label,
    }));
  };
  

  const handleStatusChange = async (task, newStatus) => {
    try {
      const token = localStorage.getItem("authToken");
      await axios.put(
        `https://api.dev1.fugesoft.com/api/tasks/${task._id}`,
        { ...task, status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const getColumnId = (status) => {
        switch (status) {
          case 'To Do': return 'todo';
          case 'In Progress': return 'inProgress';
          case 'Done': return 'done';
          default: return 'todo';
        }
      };

      const currentColumnId = getColumnId(task.status);
      const newColumnId = getColumnId(newStatus);

      const updatedTasks = { ...tasks };
      updatedTasks[currentColumnId] = updatedTasks[currentColumnId].filter(
        (t) => t._id !== task._id
      );
      updatedTasks[newColumnId] = [
        ...updatedTasks[newColumnId],
        { ...task, status: newStatus },
      ];

      setTasks(updatedTasks);
      setSelectedTask(null);
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };
  

  const handleDragStart = (task, category) => {
    setDraggedTask({ ...task, currentCategory: category });
    setTaskWasDragged(true);
  };

  const handleDrop = (category) => {
    if (draggedTask) {
      const newStatus =
        category === "todo"
          ? "To Do"
          : category === "inProgress"
          ? "In Progress"
          : "Done";
      handleStatusChange(draggedTask, newStatus);
      setDraggedTask(null);

      setTimeout(() => {
        setTaskWasDragged(false);
      }, 200);
    }
  };

  const handleTaskClick = (task, category) => {
    if (!taskWasDragged) {
      setSelectedTask({ ...task, currentCategory: category });
    }
  };

  const handleDeleteTask = async () => {
    if (selectedTask) {
      try {
        await axios.delete(
          `https://api.dev1.fugesoft.com/api/tasks/${selectedTask._id}`
        );
        const updatedTasks = { ...tasks };
        updatedTasks[selectedTask.currentCategory] = updatedTasks[
          selectedTask.currentCategory
        ].filter((t) => t._id !== selectedTask._id);
        setTasks(updatedTasks);
        setSelectedTask(null);
      } catch (error) {
        console.error("Error deleting task:", error);
      }
    }
  };

  const filteredTasks = (tasks) => {
    if (!tasks) return [];
    
    return tasks
      .filter((task) => filter === "all" || task.category === filter)
      .filter(
        (task) => assigneeFilter === "all" || task.assignedTo === assigneeFilter
      )
      .filter((task) =>
        task.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const sourceCol = source.droppableId;
    const destCol = destination.droppableId;

    // Aynı kolon içinde sıralama
    if (sourceCol === destCol) {
      const items = Array.from(tasks[sourceCol]);
      const [removed] = items.splice(source.index, 1);
      items.splice(destination.index, 0, removed);

      setTasks({
        ...tasks,
        [sourceCol]: items
      });
      return;
    }

    // Farklı kolonlar arası taşıma
    const sourceItems = Array.from(tasks[sourceCol]);
    const destItems = Array.from(tasks[destCol]);
    const [removed] = sourceItems.splice(source.index, 1);

    destItems.splice(destination.index, 0, removed);

    // Yeni durumu belirle ve API'yi güncelle
    const targetColumn = COLUMNS.find(col => col.id === destCol);
    handleStatusChange(removed, targetColumn.status);

    setTasks({
      ...tasks,
      [sourceCol]: sourceItems,
      [destCol]: destItems
    });
  };

  const renderTaskCard = (task, index) => (
    <Draggable key={task._id} draggableId={task._id} index={index}>
      {(provided, snapshot) => (
        <motion.div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className={`p-4 bg-white rounded-lg shadow-md ${
            snapshot.isDragging ? "shadow-lg scale-105" : ""
          } ${categoryColors[task.category]?.border || "border-gray-300"} border-l-4 mb-3
            hover:shadow-lg transition-all duration-200`}
        >
          <div className="flex justify-between items-start">
            <h4 
              className="font-semibold text-gray-800 cursor-pointer hover:text-blue-600"
              onClick={() => handleTaskClick(task)}
            >
              {task.title}
            </h4>
            <span className={`text-xs px-2 py-1 rounded-full ${categoryColors[task.category]?.bg} ${categoryColors[task.category]?.text}`}>
              {task.category}
            </span>
          </div>
          <div className="mt-2 text-sm text-gray-600">
            <p className="line-clamp-2">{task.description}</p>
          </div>
          <div className="mt-3 flex justify-between items-center text-xs text-gray-500">
            <div className="flex items-center space-x-2">
              <span>{task.assignedTo}</span>
            </div>
            {task.todoPet && (
              <span className="bg-gray-100 px-2 py-1 rounded">
                {task.todoPet}
              </span>
            )}
          </div>
        </motion.div>
      )}
    </Draggable>
  );

  const handleToggleHistory = () => {
    setShowHistory(!showHistory);
    if (showHistory) {
      // Refetch tasks to reflect updates in the Kanban board
      fetchTasks();
    }
  };

  const filteredHistoryTasks = () => {
    return historyTasks.filter((task) =>
      task.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };
  

  const renderHistoryTable = () => (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Başlık</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Açıklama</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Oluşturulma</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Güncelleme</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Durum</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredHistoryTasks().map((task) => (
              <tr key={task._id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">{task.title}</td>
                <td className="px-6 py-4">{task.description}</td>
                <td className="px-6 py-4 whitespace-nowrap">{new Date(task.created_at).toLocaleString()}</td>
                <td className="px-6 py-4 whitespace-nowrap">{new Date(task.updated_at).toLocaleString()}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <select
                    value={task.status}
                    onChange={(e) => handleStatusChange(task, e.target.value)}
                    className="px-3 py-1 border rounded-lg bg-gray-50 focus:ring-2 focus:ring-blue-500"
                  >
                    {statuses.map((status) => (
                      <option key={status} value={status}>{status}</option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderTasks = (tasks, category, label) => (
    <div
      onDragOver={(e) => e.preventDefault()}
      onDrop={() => handleDrop(category)}
      className="bg-white shadow-lg rounded-xl p-5 mb-4 relative"
    >
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-2">
          <h3 className="text-xl font-semibold text-gray-800">{label}</h3>
          <span className="bg-gray-100 text-gray-600 text-sm px-3 py-1 rounded-full">
            {filteredTasks(tasks[category], category).length}
          </span>
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setShowCreateModal(true);
          }}
          className="p-2 hover:bg-gray-50 rounded-full transition-all duration-200"
        >
          <FiPlus className="w-5 h-5 text-gray-600 hover:text-blue-500" />
        </button>
      </div>
      <div className="space-y-4">
        {filteredTasks(tasks[category], category).map((task) => (
          <div
            key={task._id}
            draggable
            onDragStart={() => handleDragStart(task, category)}
            className={`group p-4 bg-white rounded-xl border-l-[4px] shadow-md transition-all duration-200
              hover:shadow-xl hover:scale-[1.02]
              ${task.category === "lab"
                ? "hover:bg-red-50/50 border-red-400"
                : task.category === "istemler"
                ? "hover:bg-blue-50/50 border-blue-400"
                : task.category === "görüşmeler"
                ? "hover:bg-green-50/50 border-green-400"
                : task.category === "ilaç"
                ? "hover:bg-pink-50/50 border-pink-400"
                : "hover:bg-purple-50/50 border-purple-400"
              }`}
            onClick={(e) => {
              e.stopPropagation();
              if (!taskWasDragged) {
                handleTaskClick(task, category);
              }
            }}
          >
            <div className="flex justify-between items-start mb-3">
              <h4 className="font-bold text-lg text-gray-900 group-hover:text-gray-900">
                {task.title}
              </h4>
              <span className={`text-xs px-2.5 py-1 rounded-full font-medium
                ${task.category === "lab"
                  ? "bg-red-100 text-red-700"
                  : task.category === "istemler"
                  ? "bg-blue-100 text-blue-700"
                  : task.category === "görüşmeler"
                  ? "bg-green-100 text-green-700"
                  : task.category === "ilaç"
                  ? "bg-pink-100 text-pink-700"
                  : "bg-purple-100 text-purple-700"
                }`}
              >
                {task.category}
              </span>
            </div>
            
            <div className="space-y-3">
              <div className="flex items-center space-x-2 text-sm text-gray-500">
                <div className="flex items-center space-x-1">
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                  <span>{task.assignedTo}</span>
                </div>
                <span>•</span>
                <div className="flex items-center space-x-1">
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>{task.createdBy}</span>
                </div>
              </div>
              
              {task.todoPet && (
                <div className="flex items-center space-x-1 text-sm">
                  <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                      d="M19.8,12.6c0.8-0.8,0.8-2,0-2.8s-2-0.8-2.8,0l-1.4,1.4L14.2,9.8c0.8-0.8,0.8-2,0-2.8s-2-0.8-2.8,0L10,8.4L8.6,7
                      C7.8,6.2,6.6,6.2,5.8,7S5,8.8,5.8,9.6l1.4,1.4L5.8,12.4c-0.8,0.8-0.8,2,0,2.8s2,0.8,2.8,0l1.4-1.4l1.4,1.4c-0.8,0.8-0.8,2,0,2.8
                      s2,0.8,2.8,0l1.4-1.4l1.4,1.4c0.8,0.8,2,0.8,2.8,0s0.8-2,0-2.8l-1.4-1.4L19.8,12.6z" />
                  </svg>
                  <span className="text-gray-600 font-medium">{task.todoPet}</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {showNavbar && location.pathname !== "/" && <Navbar />}
      
      <div className="mb-6">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="relative flex-1 max-w-md">
            <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Görev ara..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          
          <div className="flex items-center space-x-4">
            <div className="relative">
              <button
                className="flex items-center space-x-2 px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                onClick={() => setShowFilters(!showFilters)}
              >
                <FiFilter className="text-gray-600" />
                <span>Filtrele</span>
              </button>
              
              {showFilters && (
                <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-200 z-10">
                  <div className="p-4">
                    <h4 className="font-semibold mb-2">Kategori</h4>
                    <div className="space-y-2">
                      {categories.map((category) => (
                        <button
                          key={category}
                          onClick={() => setFilter(category)}
                          className={`w-full text-left px-3 py-2 rounded ${
                            filter === category
                              ? `${categoryColors[category]?.bg || "bg-gray-100"} ${categoryColors[category]?.text || "text-gray-800"}`
                              : "hover:bg-gray-50"
                          }`}
                        >
                          {category.charAt(0).toUpperCase() + category.slice(1)}
                        </button>
                      ))}
                    </div>
                    
                    <h4 className="font-semibold mt-4 mb-2">Atanan Kişi</h4>
                    <select
                      value={assigneeFilter}
                      onChange={(e) => setAssigneeFilter(e.target.value)}
                      className="w-full p-2 border rounded-lg"
                    >
                      {assignees.map((assignee) => (
                        <option key={assignee} value={assignee}>
                          {assignee}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
            
            <button
              onClick={handleToggleHistory}
              className="flex items-center space-x-2 px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              <FiClock className="text-gray-600" />
              <span>{showHistory ? "Aktif" : "Geçmiş"}</span>
            </button>
          </div>
        </div>
      </div>

      {showHistory ? (
        renderHistoryTable()
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>{renderTasks(tasks, "todo", "To Do")}</div>
          <div>{renderTasks(tasks, "inProgress", "Yapılıyor")}</div>
          <div>{renderTasks(tasks, "done", "Tamamlandı")}</div>
        </div>
      )}

      <AnimatePresence>
        {selectedTask && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white rounded-lg p-6 w-full max-w-2xl relative"
            >
              <button
                className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full transition-colors"
                onClick={() => setSelectedTask(null)}
              >
                <FiX className="w-5 h-5" />
              </button>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-xl font-semibold mb-4">{selectedTask.title}</h3>
                  <textarea
                    placeholder="Add a description..."
                    className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 min-h-[150px]"
                    value={selectedTask.description || ""}
                    onChange={(e) => setSelectedTask({ ...selectedTask, description: e.target.value })}
                  />
                  <div className="mt-6">
                    <h4 className="text-lg font-semibold mb-3">Yorumlar</h4>
                    <textarea
                      placeholder="Yorum ekle..."
                      className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Durum</label>
                    <select
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                      value={selectedTask.status}
                      onChange={(e) => handleStatusChange(selectedTask, e.target.value)}
                    >
                      {statuses.map((status) => (
                        <option key={status} value={status}>{status}</option>
                      ))}
                    </select>
                  </div>

                  <div className="bg-gray-50 p-4 rounded-lg">
                    <h4 className="text-lg font-semibold mb-3">Detaylar</h4>
                    <div className="space-y-2 text-sm">
                      <p className="flex justify-between">
                        <span className="text-gray-600">Atanan:</span>
                        <span className="font-medium">{selectedTask.assignedTo}</span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Atayan:</span>
                        <span className="font-medium">{selectedTask.assignedBy}</span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Oluşturan:</span>
                        <span className="font-medium">{selectedTask.createdBy}</span>
                      </p>
                      <p className="flex justify-between">
                        <span className="text-gray-600">Kategori:</span>
                        <span className={`font-medium ${categoryColors[selectedTask.category]?.text}`}>
                          {selectedTask.category}
                        </span>
                      </p>
                    </div>
                  </div>

                  <button
                    className="w-full flex items-center justify-center space-x-2 p-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                    onClick={handleDeleteTask}
                  >
                    <FiTrash2 className="w-5 h-5" />
                    <span>Görevi Sil</span>
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showCreateModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={() => setShowCreateModal(false)}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white rounded-lg p-6 w-full max-w-md relative"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full transition-colors"
                onClick={() => setShowCreateModal(false)}
              >
                <FiX className="w-5 h-5" />
              </button>

              <h2 className="text-xl font-bold mb-6">Yeni Görev Oluştur</h2>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Başlık</label>
                  <input
                    type="text"
                    placeholder="Görev başlığı"
                    value={newTask.title}
                    onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Açıklama</label>
                  <textarea
                    placeholder="Görev açıklaması"
                    value={newTask.description}
                    onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 min-h-[100px]"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Hasta</label>
                  <Select
                    options={pets}
                    onChange={handlePetSelection}
                    placeholder="Hasta veya sahip adı ile ara..."
                    value={pets.find((pet) => pet.label === newTask.todoPet) || null}
                    className="text-sm"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Atanan Kişi</label>
                  <select
                    value={newTask.assignedTo}
                    onChange={(e) => setNewTask({ ...newTask, assignedTo: e.target.value })}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="" disabled>Atanacak kişiyi seç</option>
                    {assignees.slice(1).map((assignee) => (
                      <option key={assignee} value={assignee}>{assignee}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Oluşturan</label>
                  <input
                    type="text"
                    placeholder="Oluşturan kişi"
                    value={newTask.createdBy}
                    onChange={(e) => setNewTask({ ...newTask, createdBy: e.target.value })}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Atayan</label>
                  <input
                    type="text"
                    placeholder="Atayan kişi"
                    value={newTask.assignedBy}
                    onChange={(e) => setNewTask({ ...newTask, assignedBy: e.target.value })}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Kategori</label>
                  <select
                    value={newTask.category}
                    onChange={(e) => setNewTask({ ...newTask, category: e.target.value })}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="" disabled>Kategori seç</option>
                    {categories.slice(1).map((category) => (
                      <option key={category} value={category}>
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="mt-6 space-y-3">
                <button
                  onClick={handleCreateTask}
                  className="w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Oluştur
                </button>
                <button
                  onClick={() => setShowCreateModal(false)}
                  className="w-full bg-gray-100 text-gray-700 p-2 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  İptal
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default KanbanBoard;
