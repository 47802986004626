import React from "react";
import { FaDollarSign, FaCartPlus, FaBoxOpen, FaChartLine, FaReceipt, FaSyncAlt } from "react-icons/fa";

function SummaryCards() {
  const cards = [
    {
      icon: <FaDollarSign className="text-blue-500 text-3xl" />,
      title: "Toplam Satış",
      value: "₺45,000",
      description: "Bu ayki toplam satış tutarı",
    },
    {
      icon: <FaCartPlus className="text-green-500 text-3xl" />,
      title: "Sipariş Sayısı",
      value: "120",
      description: "Bu ay alınan toplam sipariş",
    },
    {
      icon: <FaBoxOpen className="text-red-500 text-3xl" />,
      title: "Satılmayan Stok",
      value: "350 Ürün",
      description: "Depoda bekleyen ürün miktarı",
    },
    {
      icon: <FaChartLine className="text-purple-500 text-3xl" />,
      title: "En Çok Satan Ürünler",
      value: "Mama A, İlaç B",
      description: "Bu ayın en popüler ürünleri",
    },
    {
      icon: <FaReceipt className="text-orange-500 text-3xl" />,
      title: "Yaklaşan Ödemeler",
      value: "₺12,500",
      description: "Bu ay ödenecek toplam tutar",
    },
    {
      icon: <FaSyncAlt className="text-gray-500 text-3xl" />,
      title: "Yeniden Sipariş İhtiyacı",
      value: "5 Ürün",
      description: "Kritik stok seviyesindeki ürünler",
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {cards.map((card, index) => (
        <div
          key={index}
          className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
        >
          <div className="flex items-center space-x-4">
            <div className="p-3 bg-gray-100 rounded-full">{card.icon}</div>
            <div>
              <h3 className="text-lg font-semibold">{card.title}</h3>
              <p className="text-2xl font-bold">{card.value}</p>
            </div>
          </div>
          <p className="text-sm text-gray-500 mt-3">{card.description}</p>
        </div>
      ))}
    </div>
  );
}

export default SummaryCards;
