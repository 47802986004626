import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

function NewPatientModal({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    ownerName: "",
    phoneNumber: "",
    address: "",
    idNumber: "",
    petName: "",
    petSpecies: "",
    petBreed: "",
    birthDate: "",
    weight: "",
    notes: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    // API çağrısı veya işlem kodu buraya eklenebilir.
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "600px",
        },
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" },
      }}
    >
      <h2 className="text-xl font-bold mb-4">Yeni Hasta Ekle</h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block mb-2">Hasta Sahibi Adı</label>
            <input
              type="text"
              name="ownerName"
              value={formData.ownerName}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Ad Soyad"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Telefon Numarası</label>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Telefon"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Adres</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Adres"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">TC Kimlik No</label>
            <input
              type="text"
              name="idNumber"
              value={formData.idNumber}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="TC Kimlik No"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Pet Adı</label>
            <input
              type="text"
              name="petName"
              value={formData.petName}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Pet Adı"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Tür</label>
            <input
              type="text"
              name="petSpecies"
              value={formData.petSpecies}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Pet Türü"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Cins</label>
            <input
              type="text"
              name="petBreed"
              value={formData.petBreed}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Pet Cinsi"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Doğum Tarihi</label>
            <input
              type="date"
              name="birthDate"
              value={formData.birthDate}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Kilo (kg)</label>
            <input
              type="number"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Kilo"
            />
          </div>
          <div className="mb-4 col-span-2">
            <label className="block mb-2">Ek Notlar</label>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Ek Notlar"
            ></textarea>
          </div>
        </div>
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
            onClick={onClose}
          >
            İptal
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
          >
            Kaydet
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default NewPatientModal;
