import React from "react";

function Filters({ filter, setFilter }) {
  if (!filter || typeof setFilter !== "function") {
    console.error("Filters bileşenine gerekli propslar eksik!");
    return null;
  }

  const handleDateChange = (event) => {
    setFilter((prev) => ({ ...prev, dateRange: event.target.value }));
  };

  const handleCategoryChange = (event) => {
    setFilter((prev) => ({ ...prev, category: event.target.value }));
  };

  return (
    <div className="flex space-x-4">
      <select
        value={filter.dateRange}
        onChange={handleDateChange}
        className="px-4 py-2 border rounded"
      >
        <option value="thisMonth">Bu Ay</option>
        <option value="lastMonth">Geçen Ay</option>
        <option value="last3Months">Son 3 Ay</option>
      </select>
      <select
        value={filter.category}
        onChange={handleCategoryChange}
        className="px-4 py-2 border rounded"
      >
        <option value="all">Tümü</option>
        <option value="income">Gelir</option>
        <option value="expense">Gider</option>
      </select>
    </div>
  );
}

export default Filters;
