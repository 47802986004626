import React from "react";
import Modal from "react-modal";
import { FaWhatsapp, FaPhone, FaEnvelope } from "react-icons/fa";
import moment from "moment";

Modal.setAppElement("#root");

const today = moment("2024-12-11"); // Bugünün tarihi

const dummyData = [
  {
    id: 1,
    name: "Ali Yılmaz",
    lastVisit: "2024-08-01",
    lastContact: "2024-07-15",
    status: "critical", // "critical" (kırmızı) veya "warning" (sarı)
  },
  {
    id: 2,
    name: "Fatma Öztürk",
    lastVisit: "2024-09-01",
    lastContact: "2024-09-15",
    status: "warning",
  },
  {
    id: 3,
    name: "Mehmet Ak",
    lastVisit: "2024-10-10",
    lastContact: "2024-10-15",
    status: "normal",
  },
  {
    id: 4,
    name: "Zeynep Demir",
    lastVisit: "2024-05-01",
    lastContact: "2024-05-05",
    status: "critical",
  },
  {
    id: 5,
    name: "Ahmet Kaya",
    lastVisit: "2024-11-01",
    lastContact: "2024-11-15",
    status: "normal",
  },
  {
    id: 6,
    name: "Elif Arslan",
    lastVisit: "2024-06-01",
    lastContact: "2024-06-10",
    status: "critical",
  },
].map((customer) => {
  const daysSinceContact = today.diff(moment(customer.lastContact, "YYYY-MM-DD"), "days");
  return { ...customer, daysSinceContact };
});

const CustomerReminderModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          top: "30%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -20%)",
          width: "800px",
          maxHeight: "80%",
          overflowY: "auto",
        },
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" },
      }}
    >
      <h2 className="text-xl font-semibold mb-4">Müşteri Hatırlatma</h2>
      <table className="table-auto w-full border-collapse border border-gray-200">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-200 px-4 py-2">Müşteri Adı</th>
            <th className="border border-gray-200 px-4 py-2">Son Ziyaret</th>
            <th className="border border-gray-200 px-4 py-2">Son İletişim</th>
            <th className="border border-gray-200 px-4 py-2">Kaç Gün Oldu?</th>
            <th className="border border-gray-200 px-4 py-2">Durum</th>
            <th className="border border-gray-200 px-4 py-2">İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {dummyData.map((customer) => (
            <tr
              key={customer.id}
              className={
                customer.status === "critical"
                  ? "bg-red-100"
                  : customer.status === "warning"
                  ? "bg-yellow-100"
                  : ""
              }
            >
              <td className="border border-gray-200 px-4 py-2">{customer.name}</td>
              <td className="border border-gray-200 px-4 py-2">{customer.lastVisit}</td>
              <td className="border border-gray-200 px-4 py-2">{customer.lastContact}</td>
              <td className="border border-gray-200 px-4 py-2 text-center">
                {customer.daysSinceContact} gün
              </td>
              <td className="border border-gray-200 px-4 py-2">
                {customer.status === "critical" && <span className="text-red-600 font-bold">Acil</span>}
                {customer.status === "warning" && <span className="text-yellow-600 font-bold">Uyarı</span>}
                {customer.status === "normal" && <span className="text-green-600">Normal</span>}
              </td>
              <td className="border border-gray-200 px-4 py-2 flex space-x-2">
                <button className="text-green-500 hover:text-green-600">
                  <FaWhatsapp size={20} />
                </button>
                <button className="text-blue-500 hover:text-blue-600">
                  <FaPhone size={20} />
                </button>
                <button className="text-orange-500 hover:text-orange-600">
                  <FaEnvelope size={20} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-end mt-4">
        <button
          className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
          onClick={onClose}
        >
          Kapat
        </button>
      </div>
    </Modal>
  );
};

export default CustomerReminderModal;
