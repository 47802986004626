import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

function SalesModal({ isOpen, onClose }) {
  const [customerName, setCustomerName] = useState("");
  const [product, setProduct] = useState({ name: "", quantity: 1, price: "" });
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const handleAddToCart = (e) => {
    e.preventDefault();

    if (!product.name || !product.price || product.quantity < 1) {
      alert("Lütfen geçerli ürün bilgileri giriniz.");
      return;
    }

    const updatedCart = [...cart, product];
    setCart(updatedCart);
    setTotalPrice(
      (prev) => prev + product.quantity * parseFloat(product.price)
    );
    setProduct({ name: "", quantity: 1, price: "" });
  };

  const handleRemoveFromCart = (index) => {
    const itemToRemove = cart[index];
    setTotalPrice(
      (prev) =>
        prev - itemToRemove.quantity * parseFloat(itemToRemove.price)
    );
    setCart(cart.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    if (!customerName) {
      alert("Lütfen müşteri adı giriniz.");
      return;
    }

    console.log("Satış Detayları:", {
      customerName,
      cart,
      totalPrice,
    });

    // Satış tamamlandıktan sonra tüm verileri sıfırla
    setCustomerName("");
    setCart([]);
    setTotalPrice(0);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          top: "45%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "600px",
        },
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" },
      }}
    >
      <h2 className="text-xl font-bold mb-4">Satış Yap</h2>
      <form onSubmit={handleAddToCart}>
        <div className="mb-4">
          <label className="block mb-2 font-medium">Müşteri Adı</label>
          <input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Müşteri Adı"
          />
        </div>
        <div className="grid grid-cols-3 gap-4 mb-4">
          <div>
            <label className="block mb-2 font-medium">Ürün Adı</label>
            <input
              type="text"
              value={product.name}
              onChange={(e) =>
                setProduct((prev) => ({ ...prev, name: e.target.value }))
              }
              className="w-full p-2 border rounded"
              placeholder="Ürün Adı"
            />
          </div>
          <div>
            <label className="block mb-2 font-medium">Miktar</label>
            <input
              type="number"
              min="1"
              value={product.quantity}
              onChange={(e) =>
                setProduct((prev) => ({
                  ...prev,
                  quantity: parseInt(e.target.value),
                }))
              }
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-2 font-medium">Birim Fiyat (₺)</label>
            <input
              type="number"
              min="0"
              value={product.price}
              onChange={(e) =>
                setProduct((prev) => ({
                  ...prev,
                  price: e.target.value,
                }))
              }
              className="w-full p-2 border rounded"
              placeholder="₺"
            />
          </div>
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 w-full mb-4"
        >
          Sepete Ekle
        </button>
      </form>

      <h3 className="text-lg font-bold mb-2">Sepet</h3>
      {cart.length > 0 ? (
        <div className="mb-4">
          <ul className="space-y-2">
            {cart.map((item, index) => (
              <li
                key={index}
                className="flex justify-between items-center p-2 border rounded"
              >
                <span>
                  {item.name} x {item.quantity} - ₺
                  {item.price * item.quantity}
                </span>
                <button
                  onClick={() => handleRemoveFromCart(index)}
                  className="text-red-500 hover:underline"
                >
                  Kaldır
                </button>
              </li>
            ))}
          </ul>
          <div className="text-right mt-2 font-bold">Toplam: ₺{totalPrice}</div>
        </div>
      ) : (
        <p className="text-gray-500 mb-4">Sepet boş.</p>
      )}

      <div className="flex justify-end space-x-2">
        <button
          onClick={onClose}
          className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
        >
          İptal
        </button>
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
        >
          Satış Yap
        </button>
      </div>
    </Modal>
  );
}

export default SalesModal;
