import React from 'react';

function Filters({ filter, setFilter }) {
  const handleDateRangeChange = (e) => {
    setFilter({ ...filter, dateRange: e.target.value });
  };

  const handleCategoryChange = (e) => {
    setFilter({ ...filter, category: e.target.value });
  };

  return (
    <div className="flex items-center justify-between mb-6">
      <select
        className="p-2 border rounded-lg"
        value={filter.dateRange}
        onChange={handleDateRangeChange}
      >
        <option value="today">Bugün</option>
        <option value="thisWeek">Bu Hafta</option>
        <option value="thisMonth">Bu Ay</option>
        <option value="thisYear">Bu Yıl</option>
      </select>
      <select
        className="p-2 border rounded-lg"
        value={filter.category}
        onChange={handleCategoryChange}
      >
        <option value="all">Hepsi</option>
        <option value="income">Gelir</option>
        <option value="expense">Gider</option>
      </select>
    </div>
  );
}

export default Filters;
