import React from 'react';
import { FaFilePdf, FaFileExcel } from 'react-icons/fa';

function ExportButtons() {
  const handleExportPDF = () => {
    // PDF dışa aktarma işlemi
    console.log('PDF olarak dışa aktarılıyor...');
  };

  const handleExportExcel = () => {
    // Excel dışa aktarma işlemi
    console.log('Excel olarak dışa aktarılıyor...');
  };

  return (
    <div className="flex space-x-4">
      <button
        onClick={handleExportPDF}
        className="flex items-center px-4 py-2 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600 transition duration-200"
      >
        <FaFilePdf className="mr-2 text-xl" />
        PDF Dışa Aktar
      </button>
      <button
        onClick={handleExportExcel}
        className="flex items-center px-4 py-2 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600 transition duration-200"
      >
        <FaFileExcel className="mr-2 text-xl" />
        Excel Dışa Aktar
      </button>
    </div>
  );
}

export default ExportButtons;
