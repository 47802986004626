import React, { useState } from 'react';
import { FaSearch, FaUserCircle } from 'react-icons/fa'; // Icons for search and user avatars

const clients = [
  { id: 1, name: 'John Doe', petName: 'Bella' },
  { id: 2, name: 'Jane Smith', petName: 'Max' },
  { id: 3, name: 'Michael Jordan', petName: 'Buddy' },
];

function ChatSidebar({ selectClient, unread }) {
  const [searchQuery, setSearchQuery] = useState('');

  // Filter clients based on search query
  const filteredClients = clients.filter((client) =>
    client.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="w-72 bg-gray-900 text-white shadow-lg flex flex-col h-5/5">
      {/* Header */}
      <div className="flex items-center justify-center bg-blue-600 p-4 shadow-md">
        <h2 className="text-lg font-semibold">Müşteri Listesi</h2>
      </div>

      {/* Search Input */}
      <div className="relative p-4">
        <input
          type="text"
          placeholder="Ara..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full pl-10 p-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <FaSearch className="absolute left-7 top-6 text-gray-400" />
      </div>

      {/* Client List */}
      <ul className="flex-1 overflow-y-auto">
        {filteredClients.map((client) => (
          <li
            key={client.id}
            className="flex items-center p-3 mb-2 cursor-pointer bg-gray-800 hover:bg-blue-600 rounded-lg transition-all duration-200"
            onClick={() => selectClient(client)}
          >
            <FaUserCircle className="text-3xl text-gray-400 mr-3" />
            <div className="flex-1">
              <div className="flex justify-between">
                <span className="text-lg font-medium">{client.name}</span>
                {unread[client.id] && (
                  <span className="text-red-500 text-sm font-bold">•</span>
                )}
              </div>
              <small className="text-gray-400">{client.petName}</small>
            </div>
          </li>
        ))}
      </ul>

      {/* Footer */}
      <div className="p-4 text-center bg-gray-800">
        <small className="text-gray-500">
          © {new Date().getFullYear()} fugesoft
        </small>
      </div>
    </div>
  );
}

export default ChatSidebar;
