import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Modal from "react-modal";
import { FaEdit, FaTrash, FaCalendarAlt, FaClock, FaUser } from "react-icons/fa";
import moment from "moment";
import axios from "axios";
import "moment/locale/tr";
import AppointmentModal from "../../../../components/AppointmentModal";

Modal.setAppElement("#root");

function AppointmentsSchedule() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [appointments, setAppointments] = useState([]);
  const [newEvent, setNewEvent] = useState({
    petId: "",
    owner_name: "",
    pet_name: "",
    pet_species: "",
    pet_age: 0,
    assignedTo: "",
    description: "",
    title: "",
    date: moment().format("YYYY-MM-DD"),
    startTime: "",
    endTime: "",
  });
  const [pets, setPets] = useState([]);
  const [users, setUsers] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    fetchAppointments(selectedDate);
    fetchPets();
    fetchUsers();
  }, [selectedDate]);

  const fetchAppointments = async (date) => {
    try {
      // Seçili ayın ilk ve son günlerini al
      const startDate = moment(date).startOf('month').format('YYYY-MM-DD');
      const endDate = moment(date).endOf('month').format('YYYY-MM-DD');

      const response = await axios.get(
        `https://api.dev1.fugesoft.com/api/appointments?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const appointmentsData = response.data.appointments || [];
      
      if (!Array.isArray(appointmentsData)) {
        console.error("Appointments data is not an array:", appointmentsData);
        return;
      }

      const formattedAppointments = appointmentsData.map((item) => ({
        id: item._id,
        date: item.date || "",
        saat: item.time || "",
        baslik: item.title || "",
        hasta: item.owner_name || "Bilinmiyor",
        renk: "border-l-4 border-blue-500",
      }));
      
      setAppointments(formattedAppointments);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const fetchPets = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/pets", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const petOptions = response.data.map((pet) => ({
        value: pet._id,
        label: `${pet.name} - ${pet.owner_name}`,
        pet_species: pet.species || "Bilinmiyor",
        pet_age: pet.age || 0,
      }));
      setPets(petOptions);
    } catch (error) {
      console.error("Error fetching pets:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userOptions = response.data.map((user) => ({
        value: user.id,
        label: `${user.name} ${user.surname} - ${user.role}`,
      }));
      setUsers(userOptions);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const openModal = () => {
    setNewEvent({
      petId: "",
      owner_name: "",
      pet_name: "",
      pet_species: "",
      pet_age: 0,
      assignedTo: "",
      description: "",
      title: "",
      date: moment(selectedDate).format("YYYY-MM-DD"),
      startTime: "",
      endTime: "",
    });
    setModalIsOpen(true);
  };

  const closeModal = () => setModalIsOpen(false);

  const handleAddEvent = async () => {
    try {
      const newAppointment = {
        petId: newEvent.petId,
        assignedTo: newEvent.assignedTo,
        owner_name: newEvent.owner_name,
        pet_name: newEvent.pet_name,
        pet_species: newEvent.pet_species,
        pet_age: newEvent.pet_age,
        description: newEvent.description,
        title: newEvent.title,
        date: newEvent.date,
        time: `${newEvent.startTime} - ${newEvent.endTime}`,
      };

      const response = await axios.post(
        "https://api.dev1.fugesoft.com/api/appointments",
        newAppointment,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const newEntry = {
        id: response.data._id,
        date: newEvent.date,
        saat: `${newEvent.startTime} - ${newEvent.endTime}`,
        baslik: newEvent.title,
        hasta: newEvent.owner_name,
        renk: "border-l-4 border-green-500",
      };

      setAppointments((prev) => [...prev, newEntry]);
      closeModal();
    } catch (error) {
      console.error("Error adding appointment:", error);
    }
  };

  const deleteAppointment = async (appointmentId) => {
    try {
      await axios.delete(`https://api.dev1.fugesoft.com/api/appointments/${appointmentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Silinen randevuyu state'den kaldır
      setAppointments((prev) => prev.filter((appointment) => appointment.id !== appointmentId));
    } catch (error) {
      console.error("Error deleting appointment:", error);
    }
  };

  const filteredAppointments = appointments
    .filter((appointment) => appointment.date === moment(selectedDate).format("YYYY-MM-DD"))
    .sort((a, b) => {
      const timeA = a.saat.split(" - ")[0];
      const timeB = b.saat.split(" - ")[0];
      return moment(timeA, "HH:mm").diff(moment(timeB, "HH:mm"));
    });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="bg-white rounded-lg shadow-md">
      {/* Header */}
      <div className="p-4 border-b">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-gray-800">Randevu Planlayıcı</h2>
          <button
            onClick={() => setShowCalendar(!showCalendar)}
            className="text-blue-500 hover:text-blue-700 flex items-center gap-2 text-sm font-medium"
          >
            <FaCalendarAlt />
            {showCalendar ? "Takvimi Gizle" : "Takvimi Göster"}
          </button>
        </div>
      </div>

      <div className="flex flex-col">
        {/* Calendar Section */}
        {showCalendar && (
          <div className="p-4 border-b">
            <Calendar
              className="w-full max-w-md mx-auto border rounded-lg shadow-sm"
              locale="tr-TR"
              onChange={handleDateChange}
              value={selectedDate}
              tileClassName={({ date }) => {
                const hasAppointment = appointments.some(
                  (appointment) => appointment.date === moment(date).format("YYYY-MM-DD")
                );
                return hasAppointment ? "has-appointment rounded-lg hover:bg-blue-50" : "rounded-lg hover:bg-blue-50";
              }}
              tileContent={({ date }) => {
                const dayAppointments = appointments.filter(
                  (appointment) => appointment.date === moment(date).format("YYYY-MM-DD")
                );
                return dayAppointments.length > 0 ? (
                  <div className="appointment-dot">
                    <span className="text-xs text-blue-500 font-semibold">{dayAppointments.length}</span>
                  </div>
                ) : null;
              }}
            />
          </div>
        )}

        {/* Appointments Section */}
        <div className="p-4">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-4">
              <button
                onClick={() => setSelectedDate(moment(selectedDate).subtract(1, "days").toDate())}
                className="p-2 text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded-lg transition-colors"
              >
                &lt;
              </button>
              <h3 className="text-lg font-medium text-gray-800">
                {moment(selectedDate).locale("tr").format("LL")}
              </h3>
              <button
                onClick={() => setSelectedDate(moment(selectedDate).add(1, "days").toDate())}
                className="p-2 text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded-lg transition-colors"
              >
                &gt;
              </button>
            </div>
            <button
              onClick={openModal}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors flex items-center gap-2 text-sm font-medium"
            >
              <FaCalendarAlt className="text-white" />
              Yeni Randevu
            </button>
          </div>

          {/* Appointments List */}
          <div className="space-y-3">
            {filteredAppointments.length === 0 ? (
              <div className="text-center py-8 text-gray-500">
                Bu tarihte randevu bulunmamaktadır.
              </div>
            ) : (
              filteredAppointments.map((randevu, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg border p-4 hover:shadow-md transition-shadow"
                >
                  <div className="flex justify-between items-start">
                    <div className="space-y-2">
                      <h4 className="font-semibold text-gray-800">{randevu.baslik}</h4>
                      <div className="flex items-center gap-4 text-sm text-gray-600">
                        <div className="flex items-center gap-1">
                          <FaClock className="text-gray-400" />
                          {randevu.saat}
                        </div>
                        <div className="flex items-center gap-1">
                          <FaUser className="text-gray-400" />
                          {randevu.hasta}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <button className="p-2 text-blue-500 hover:bg-blue-50 rounded-lg transition-colors">
                        <FaEdit />
                      </button>
                      <button 
                        onClick={() => deleteAppointment(randevu.id)}
                        className="p-2 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {/* Appointment Modal */}
      <AppointmentModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        pets={pets}
        users={users}
        newEvent={newEvent}
        setNewEvent={setNewEvent}
        handleAddEvent={handleAddEvent}
      />
      <style>
        {`
          .has-appointment {
            background-color: rgba(59, 130, 246, 0.1);
            font-weight: bold;
            color: #2563eb;
          }
          .appointment-dot {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2px;
          }
        `}
      </style>
    </div>
  );
}

export default AppointmentsSchedule;
