import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar"; // Yeni eklenen bileşen
import LoginScreen from "./screens/Authentication/LoginScreen";
import ChangePasswordScreen from "./screens/Authentication/ChangePasswordScreen";
import PetOwnerScreen from "./servisler/yogunBakımServisi/Customers/PetOwnerScreen";
import PetOwnerDetailsScreen from "./servisler/yogunBakımServisi/Customers/PetOwnerDetailsScreen";
import CalendarScreen from "./servisler/klinikTakipServisi/Calender/CalenderScreen";
import OverviewKanban from "./servisler/yogunBakımServisi/OverviewKanban";
import PetList from "./servisler/yogunBakımServisi/HayvanListesi/PetList";
import KanbanBoard from "./servisler/yogunBakımServisi/KanbanBoard/KanbanBoard";
import PetListScreen from "./servisler/yogunBakımServisi/YatanHastaListesi/PetListScreen";
import PetDetails from "./servisler/klinikTakipServisi/PetList/PetDetails";
import IstekPet from "./servisler/yogunBakımServisi/YatanHastaListesi/IstekPet/IstekPet";
import OverviewScreen from "./servisler/klinikTakipServisi/OverviewScreen";
import Navbar from "./components/Navbar";
import MessagingScreen from "./servisler/vetAssistServisi/MessagingScreen";
import StockManagementScreen from "./servisler/klinikTakipServisi/StockManagement/StockManagementScreen";
import PatientsClinic from "./servisler/klinikTakipServisi/PatientsClinic";
import FinanceAnalyzeScreen from "./servisler/FinanceAnalyze/FinanceAnalyzeScreen";
import InvoiceManagement from "./servisler/FinanceAnalyze/Faturalar/InvoiceManagement";
import Profile from "./screens/Authentication/Profile";
import OnlineReceteScreen from "./servisler/onlineRecete/OnlineReceteScreen";
import IlacSupplement from "./servisler/klinikTakipServisi/Supplements/IlacSupplement";
import AsiTakvimiScreen from "./servisler/klinikTakipServisi/AsiTakvimi/AsiTakvimiScreen";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("authToken")
  );
  const [activeModule, setActiveModule] = useState("Klinik Takip");

  const handleTokenChange = () => {
    const token = localStorage.getItem("authToken");
    setIsAuthenticated(!!token);
  };

  const handleModuleSelect = (moduleName) => {
    setActiveModule(moduleName);
  };

  useEffect(() => {
    window.addEventListener("storage", handleTokenChange);
    return () => {
      window.removeEventListener("storage", handleTokenChange);
    };
  }, []);

  const appStyle = {
    background: isAuthenticated 
      ? 'linear-gradient(135deg, #f6f8fd 0%, #e3e8f4 50%, #d5dced 100%)'
      : 'none',
    minHeight: '100vh',
  };

  const contentStyle = {
    backgroundColor: isAuthenticated ? 'rgba(255, 255, 255, 0.7)' : 'transparent',
    minHeight: '100vh',
    backdropFilter: 'blur(10px)',
  };

  return (
    <div style={appStyle}>
      <div style={contentStyle}>
        <Router>
          <div className="h-screen flex flex-col">
            {/* Header */}
            {isAuthenticated && <Header />}

            {/* Navbar */}
            {isAuthenticated && <Navbar activeModule={activeModule} />}

            <div className="flex flex-1">
              {/* Sidebar */}
              {isAuthenticated && (
                <Sidebar
                  activeModule={activeModule}
                  onModuleSelect={handleModuleSelect}
                />
              )}

              {/* Main Content */}
              <div className="flex-1 ml-32 mr-12">
                <div>
                  <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                  <Routes>
                    {!isAuthenticated ? (
                      <>
                        <Route
                          path="/login"
                          element={
                            <div className="ml-[-15vh]  mr-[-4vh]">
                              <LoginScreen />
                            </div>
                          }
                        />
                        <Route path="*" element={<Navigate to="/login" />} />
                      </>
                    ) : (
                      <>
                        <Route path="/" element={<OverviewScreen />} />
                        <Route
                          path="/change-password"
                          element={<ChangePasswordScreen />}
                        />
                        <Route path="/patients" element={<PetOwnerScreen />} />
                        <Route
                          path="/pet-owner/:owner_id"
                          element={<PetOwnerDetailsScreen />}
                        />
                        <Route path="/calendar" element={<CalendarScreen />} />
                        <Route path="/petlist" element={<PetList />} />
                        <Route path="/kanban-board" element={<KanbanBoard />} />
                        <Route path="/pet-list" element={<PetListScreen />} />
                        <Route path="/pet-details/:petId" element={<PetDetails />} />
                        <Route path="/istek-pet/:petId" element={<IstekPet />} />
                        <Route path="/overview" element={<OverviewKanban />} />
                        <Route path="/randevutakvimi" element={<CalendarScreen />} />
                        <Route path="/vetassist" element={<MessagingScreen />} />
                        <Route
                          path="/stok-yonetimi"
                          element={<StockManagementScreen />}
                        />
                        <Route path="/müsteri-listesi" element={<PatientsClinic />} />
                        <Route path="/finance" element={<FinanceAnalyzeScreen />} />
                        <Route
                          path="/fatura-yonetimi"
                          element={<InvoiceManagement />}
                        />
                        <Route path="/profile" element={<Profile />} />
                        <Route
                          path="/online-recete"
                          element={<OnlineReceteScreen />}
                        />
                        <Route
                          path="/klinik-takip/ilac-supplement"
                          element={
                            isAuthenticated ? (
                              <IlacSupplement />
                            ) : (
                              <Navigate to="/login" replace />
                            )
                          }
                        />
                        <Route
                          path="/asi-takvimi"
                          element={
                            isAuthenticated ? (
                              <AsiTakvimiScreen />
                            ) : (
                              <Navigate to="/login" replace />
                            )
                          }
                        />
                      </>
                    )}
                  </Routes>
                </div>
              </div>
            </div>

            {/* Footer */}
            {isAuthenticated && <Footer />}
          </div>
        </Router>
      </div>
    </div>
  );
}

export default App;
