import React, { useState } from "react";
import InvoiceTable from "./InvoiceTable";
import InvoiceDetails from "./InvoiceDetails";
import NewInvoiceModal from "./NewInvoiceModal";
import InvoiceSummaryCards from "./InvoiceSummaryCards";
import Filters from "./FiltersFatura";

function InvoiceManagement() {
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isNewInvoiceModalOpen, setIsNewInvoiceModalOpen] = useState(false);

  const handleNewInvoice = () => {
    setIsNewInvoiceModalOpen(true);
  };

  const closeNewInvoiceModal = () => {
    setIsNewInvoiceModalOpen(false);
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-bold mb-6">Fatura Yönetimi</h1>
      <Filters />
      <InvoiceSummaryCards />
      <div className="mt-6">
        <InvoiceTable onSelectInvoice={setSelectedInvoice} />
      </div>
      <div className="mt-4 flex justify-end">
        <button
          className="bg-green-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-green-700"
          onClick={handleNewInvoice}
        >
          Yeni Fatura Oluştur
        </button>
      </div>
      {selectedInvoice && (
        <InvoiceDetails
          invoice={selectedInvoice}
          onClose={() => setSelectedInvoice(null)}
        />
      )}
      {isNewInvoiceModalOpen && (
        <NewInvoiceModal onClose={closeNewInvoiceModal} />
      )}
    </div>
  );
}

export default InvoiceManagement;
