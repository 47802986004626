import React, { useEffect, useRef } from "react";
import { Chart, PieController, ArcElement, Tooltip, Legend } from "chart.js";

Chart.register(PieController, ArcElement, Tooltip, Legend);

function PieChart({ filter }) {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const chart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: ["Hizmet Gelirleri", "Ürün Satışları", "Personel Giderleri", "Kira Giderleri"],
        datasets: [
          {
            data: [5000, 7000, 3000, 2000],
            backgroundColor: ["#60A5FA", "#FBBF24", "#34D399", "#F87171"],
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${context.raw} ₺`;
              },
            },
          },
        },
      },
    });

    return () => chart.destroy(); // Çakışmayı önlemek için grafik yok ediliyor.
  }, [filter]);

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h3 className="text-lg font-semibold mb-4">Kategori Dağılımı</h3>
      <canvas ref={chartRef}></canvas>
    </div>
  );
}

export default PieChart;
